import {client} from '../api/client'
import {unit_map} from './unit_map'
import {thumbs} from './bookreport_thumbnail_map'
import {rpt_pdfs} from './bookreport_pdf_map'
import {emerging_map,pre_readers_map,fluency_map} from './lesson_plan_map'

const S3_SCOPE_AND_SEQUENCE_URLS = {
  book_report_thumbnails: "https://sw-materials-documents.s3.amazonaws.com/materials/scope-and-sequence/book-report-thumbnails/",
  book_report_pdfs: "https://sw-materials-documents.s3.amazonaws.com/materials/scope-and-sequence/pdf-items/book-report-pdf/",
  activity_sheet_frag: "https://sw-materials-documents.s3.amazonaws.com/materials/",
  lesson_plan_pre_readers: "https://sw-materials-documents.s3.amazonaws.com/materials/scope-and-sequence/pdf-items/lesson-plan-pdf-pre-reader/",
  lesson_plan_emergent: "https://sw-materials-documents.s3.amazonaws.com/materials/scope-and-sequence/pdf-items/lesson-plan-pdf-emerging-reader/",
  lesson_plan_fluency: "https://sw-materials-documents.s3.amazonaws.com/materials/scope-and-sequence/pdf-items/lesson-plan-pdf-fluency/"
}

const books = () => {
    //json should be sent through url to remove redundancy 
    //const url = `https://swlb.storyworld.io/`+process.env.REACT_APP_THUMBNAIL_SRV_VERSION+`/8I5RAGGN2U40Q0/Books/dist/library.json`

    return {
      "configuration": "All",
      "version": "1.01",
      "contentTypes": {
        "default": {
          "scoredSections": {
            "EN-ES": 3,
            "EN-ZH": 3
          }
        },
        "word-book": {
          "scoredSections": {
            "EN-ES": 1,
            "EN-ZH": 1
          }
        },
        "full-length": {
          "scoredSections": {
            "EN-ES": 0,
            "EN-ZH": 0
          }
        }
      },
      "library": [
        {
          "set": 1,
          "path": "1-Food_",
          "sortkey": {
            "EN": "8",
            "ES": "18",
            "ZH": "6"
          },
          "title": {
            "EN": "Food!",
            "ES": "¡Comida!",
            "ZH": "食物！"
          },
          "level": {
            "EN": 2,
            "ES": 3,
            "ZH": 1
          },
          "lexile": {
            "EN": 110,
            "ES": 380,
            "ZH": 110
          },
          "fp": "D",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Count to ten while learning the words for colors, fruits, and vegetables.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "colors",
            "numbers",
            "food"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "green",
            "white",
            "brown",
            "blue",
            "orange",
            "yellow"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-Food EN.pdf",
              "ES": "Proficiency Assessment-Food ES.pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_Food_Worksheet_EN.pdf",
              "ES": "SB_Food_Worksheet_ES.pdf",
              "ZH": "SB_Food_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Food.pdf"
            }
          }
        },
        {
          "set": 1,
          "path": "1-MonkeysSaveTheMoon",
          "sortkey": {
            "EN": "50",
            "ES": "56",
            "ZH": "46"
          },
          "title": {
            "EN": "Monkeys Save the Moon",
            "ES": "Los monos salvan la luna",
            "ZH": "猴子捞月亮"
          },
          "level": {
            "EN": 4,
            "ES": 5,
            "ZH": 4
          },
          "lexile": {
            "EN": 570,
            "ES": 800,
            "ZH": 570
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A fun Chinese fable about a group of monkeys who try to “save” the moon they believe has fallen into a well.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "animals",
            "space"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "hide",
            "moon",
            "monkey",
            "save",
            "light",
            "hide and seek",
            "well",
            "ladder",
            "moonlight"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Monkeys Save the Moon (EN).pdf",
              "ES": "Proficiency Assessment-Monkeys save the moon (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MonkeysSaveTheMoon_Worksheet_EN.pdf",
              "ES": "SB_MonkeysSaveTheMoon_Worksheet_ES.pdf",
              "ZH": "SB_MonkeysSaveTheMoon_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Monkeys Save the Moon.pdf"
            }
          }
        },
        {
          "set": 1,
          "path": "1-MySenses",
          "sortkey": {
            "EN": "1.03",
            "ES": "1.03",
            "ZH": "1.03"
          },
          "title": {
            "EN": "My Senses",
            "ES": "Mis sentidos",
            "ZH": "我的感官"
          },
          "level": {
            "EN": 1,
            "ES": 1,
            "ZH": 1
          },
          "lexile": {
            "EN": -1,
            "ES": 60,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Learn about our five senses and practice personal pronouns.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "body"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "to see",
            "to hear",
            "to taste",
            "to feel",
            "to smell",
            "to sleep",
            "tired"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - My Senses (EN).pdf",
              "ES": "Proficiency Assessment-My senses (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MySenses_Worksheet_EN.pdf",
              "ES": "SB_MySenses_Worksheet_ES.pdf",
              "ZH": "SB_MySenses_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My Senses.pdf"
            }
          }
        },
        {
          "set": 1,
          "path": "1-TheAntAndTheGrasshopper",
          "sortkey": {
            "EN": "47",
            "ES": "32",
            "ZH": "44",
            "VI": "47",
            "AR": "47",
            "KO": "47",
            "DR": "47",
            "PS": "47",
            "TL": "47",
            "BN": "47",
            "PT": "47",
            "HK": "47",
            "TW": "47"
          },
          "title": {
            "EN": "The Ant and the Grasshopper",
            "ES": "La hormiga y el saltamontes",
            "ZH": "蚂蚁和蚂蚱",
            "VI": "Kiến và Châu chấu",
            "AR": "النملة والجندب",
            "KO": "개미와 베짱이",
            "DR": "مورچه و ملخ",
            "PS": "میږی او ملخ",
            "TL": "Ang Langgam at ang Tipaklong",
            "BN": "পিঁপড়া এবং ফড়িং",
            "PT": "A Formiga e o Gafanhoto",
            "HK": "螞蟻和螞蚱",
            "TW": "螞蟻和螞蚱"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "lexile": {
            "EN": 520,
            "ES": 500,
            "ZH": 520,
            "VI": 520,
            "AR": 520,
            "KO": 520,
            "DR": 520,
            "PS": 520,
            "TL": 520,
            "BN": 520,
            "PT": 520,
            "HK": 520,
            "TW": 520
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-PS",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW"
          ],
          "about": "An ant collects food for winter while a grasshopper sings but learns a tough lesson at the end of this classic Aesop’s Fable.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "animals",
            "morals"
          ],
          "grammar": [
            "present",
            "past",
            "conditional"
          ],
          "keywords": [
            "ant",
            "grasshopper",
            "food",
            "to sing",
            "winter",
            "to work"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - The Ant and the Grasshopper (EN).pdf",
              "ES": "Proficiency Assessment- the ant and the grasshopper (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheAntAndTheGrasshopper_Worksheet_EN.pdf",
              "ES": "SB_TheAntAndTheGrasshopper_Worksheet_ES.pdf",
              "ZH": "SB_TheAntAndTheGrasshopper_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Ant and the Grasshopper.pdf"
            }
          }
        },
        {
          "set": 1,
          "path": "1-TheMagicMirror",
          "sortkey": {
            "EN": "46",
            "ES": "52",
            "ZH": "56"
          },
          "title": {
            "EN": "The Magic Mirror",
            "ES": "El espejo mágico",
            "ZH": "魔镜"
          },
          "level": {
            "EN": 4,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 510,
            "ES": 640,
            "ZH": 510
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The King wants a queen, but who will pass the magic mirror’s test? A classic Spanish Tale.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "morals"
          ],
          "grammar": [
            "present",
            "past",
            "future",
            "conditional"
          ],
          "keywords": [
            "barber",
            "queen",
            "mirror",
            "king",
            "people",
            "spot",
            "afraid",
            "brave"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-Magic Mirror EN.pdf",
              "ES": "Proficiency Assessment-Magic Mirror (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheMagicMirror_Worksheet_EN.pdf",
              "ES": "SB_TheMagicMirror_Worksheet_ES.pdf",
              "ZH": "SB_TheMagicMirror_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Magic Mirror.pdf"
            }
          }
        },
        {
          "set": 1,
          "path": "1-TheMoonLady",
          "sortkey": {
            "EN": "56",
            "ES": "58",
            "ZH": "53"
          },
          "title": {
            "EN": "The Moon Lady",
            "ES": "Señora de la luna",
            "ZH": "月亮女神"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 720,
            "ES": 820,
            "ZH": 720
          },
          "fp": "Q",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A young hero saves the earth and finds his love, but then the lovers become separated in this famous Chinese legend.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "animals",
            "holidays",
            "space"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "Earth",
            "archer",
            "bottle",
            "rabbit",
            "moon",
            "bridge",
            "sun"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - The Moon Lady (EN).pdf",
              "ES": "Proficiency Assessment-Moon lady (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheMoonLady_Worksheet_EN.pdf",
              "ES": "SB_TheMoonLady_Worksheet_ES.pdf",
              "ZH": "SB_TheMoonLady_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Moon Lady.pdf"
            }
          }
        },
        {
          "set": 1,
          "path": "1-TheNineDragonSons",
          "sortkey": {
            "EN": "23",
            "ES": "37",
            "ZH": "10"
          },
          "title": {
            "EN": "The Nine Dragon Sons",
            "ES": "Los nueve hijos del dragón",
            "ZH": "龙爸爸的九个儿子"
          },
          "level": {
            "EN": 3,
            "ES": 4,
            "ZH": 2
          },
          "lexile": {
            "EN": 360,
            "ES": 520,
            "ZH": 360
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "An ancient Chinese tale about the nine sons of a dragon and what makes each of them distinct.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "morals",
            "family"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "dragon",
            "son",
            "music",
            "water",
            "fire",
            "to read",
            "to eat"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - The Nine Dragon Sons (EN).pdf",
              "ES": "Proficiency Assessment-Nine Dragon Sons (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheNineDragonSons_Worksheet_EN.pdf",
              "ES": "SB_TheNineDragonSons_Worksheet_ES.pdf",
              "ZH": "SB_TheNineDragonSons_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Nine Dragon Sons.pdf"
            }
          }
        },
        {
          "set": 1,
          "path": "1-TheUglyDuckling",
          "sortkey": {
            "EN": "26",
            "ES": "29",
            "ZH": "13"
          },
          "title": {
            "EN": "The Ugly Duckling",
            "ES": "El patito feo",
            "ZH": "丑小鸭"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 2
          },
          "lexile": {
            "EN": 370,
            "ES": 470,
            "ZH": 370
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A little duckling thinks he is ugly until he grows up to become a beautiful swan. Adapted from the Hans Christian Andersen story.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "animals",
            "morals"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "duck",
            "egg",
            "ugly",
            "fall",
            "to swim",
            "swan"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-Ugly Duckling EN.pdf",
              "ES": "Proficiency Assessment-Ugly Duckling (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheUglyDuckling_Worksheet_EN.pdf",
              "ES": "SB_TheUglyDuckling_Worksheet_ES.pdf",
              "ZH": "SB_TheUglyDuckling_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Ugly Duckling.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-Clouds",
          "sortkey": {
            "EN": "10",
            "ES": "5",
            "ZH": "7"
          },
          "title": {
            "EN": "Clouds",
            "ES": "Nubes",
            "ZH": "云",
            "KO": "구름들"
          },
          "level": {
            "EN": 2,
            "ES": 2,
            "ZH": 1,
            "KO": 1
          },
          "lexile": {
            "EN": 140,
            "ES": 140,
            "ZH": 140
          },
          "fp": "E",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-KO"
          ],
          "about": "Children watch clouds that look like big and small animals drift across the sky.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "animals",
            "sizes"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "pig",
            "horse",
            "monkey",
            "rabbit",
            "sheep",
            "duck"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Clouds (EN).pdf",
              "ES": "Proficiency Assessment-Clouds (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_Clouds_Worksheet_EN.pdf",
              "ES": "SB_Clouds_Worksheet_ES.pdf",
              "ZH": "SB_Clouds_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Clouds.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-Let_sGo_",
          "sortkey": {
            "EN": "6",
            "ES": "6",
            "ZH": "4"
          },
          "title": {
            "EN": "Let's Go!",
            "ES": "¡Vamos!",
            "ZH": "我们走吧！"
          },
          "level": {
            "EN": 1,
            "ES": 2,
            "ZH": 1
          },
          "lexile": {
            "EN": 60,
            "ES": 140,
            "ZH": 60
          },
          "fp": "B",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "People use different forms of transportation to go places around the world.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "location/direction",
            "transit",
            "travel"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "train",
            "bike",
            "airplane",
            "car",
            "boat",
            "bus"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Lets Go (EN).pdf",
              "ES": "Proficiency Assessment-lets go (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_LetsGo_Worksheet_EN.pdf",
              "ES": "SB_LetsGo_Worksheet_ES.pdf",
              "ZH": "SB_LetsGo_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Let_s Go.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-LookAtTheSky_",
          "sortkey": {
            "EN": "1.02",
            "ES": "1.02",
            "ZH": "1.02"
          },
          "title": {
            "EN": "Look At The Sky!",
            "ES": "¡Mira el cielo!",
            "ZH": "你看天空！"
          },
          "level": {
            "EN": 1,
            "ES": 1,
            "ZH": 1
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Look at the sky to learn about different weather and temperatures.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "space",
            "weather"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "sun",
            "cloud",
            "snow",
            "rain",
            "moon",
            "star"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Look At the Sky (EN).pdf",
              "ES": "Proficiency Assessment-look at the sky (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_LookAtTheSky_Worksheet_EN.pdf",
              "ES": "SB_LookAtTheSky_Worksheet_ES.pdf",
              "ZH": "SB_LookAtTheSky_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Look at the Sky!.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-MyFunnyClassmates",
          "sortkey": {
            "EN": "32",
            "ES": "38",
            "ZH": "24"
          },
          "title": {
            "EN": "My Funny Classmates",
            "ES": "Mis divertidos compañeros de clase",
            "ZH": "我有趣的同学们"
          },
          "level": {
            "EN": 3,
            "ES": 4,
            "ZH": 3
          },
          "lexile": {
            "EN": 400,
            "ES": 520,
            "ZH": 400
          },
          "fp": "L",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "There’s something funny about every classmate and the colorful costumes they wear on their bodies.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "body",
            "school"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "hair",
            "eye",
            "nose",
            "the tooth",
            "hand",
            "ear"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - My Funny Classmates (EN).pdf",
              "ES": "Proficiency Assessment- my funny classmates (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MyFunnyClassmates_Worksheet_EN.pdf",
              "ES": "SB_MyFunnyClassmates_Worksheet_ES.pdf",
              "ZH": "SB_MyFunnyClassmates_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My Funny Classmates.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-ShoppingWithMom",
          "sortkey": {
            "EN": "42",
            "ES": "40",
            "ZH": "23"
          },
          "title": {
            "EN": "Shopping with Mom",
            "ES": "De compras con mamá",
            "ZH": "跟妈妈一起逛街"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 2
          },
          "lexile": {
            "EN": 490,
            "ES": 540,
            "ZH": 490
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Mom takes forever choosing the best clothing, groceries, and toys, but she is always thoughtful.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "family"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "clothes",
            "dress",
            "toy",
            "box",
            "food",
            "vegetable"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-Shopping with mom EN.pdf",
              "ES": "Proficiency Assessment-Shopping with mom (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_ShoppingWithMom_Worksheet_EN.pdf",
              "ES": "SB_ShoppingWithMom_Worksheet_ES.pdf",
              "ZH": "SB_ShoppingWithMom_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Shopping With Mom.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-TheSpearAndShieldSalesman",
          "sortkey": {
            "EN": "38",
            "ES": "30",
            "ZH": "54"
          },
          "title": {
            "EN": "The Spear and Shield Salesman",
            "ES": "El vendedor de lanzas y escudos",
            "ZH": "自相矛盾:卖矛和盾的人"
          },
          "level": {
            "EN": 4,
            "ES": 3,
            "ZH": 5
          },
          "lexile": {
            "EN": 480,
            "ES": 480,
            "ZH": 480
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "In this ancient Chinese story, one boy’s question leaves a great salesman speechless.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "morals"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "spear",
            "shield",
            "salesman",
            "boy",
            "people",
            "world",
            "cut",
            "strong"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment -The Spear and Shield Salesman (EN).pdf",
              "ES": "Proficiency Assessment-spear and shield salesman ES.pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheSpearAndShieldSalesman_Worksheet_EN.pdf",
              "ES": "SB_TheSpearAndShieldSalesman_Worksheet_ES.pdf",
              "ZH": "SB_TheSpearAndShieldSalesman_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Spear and Shield Salesman.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-WhereAreMyPets_",
          "sortkey": {
            "EN": "7",
            "ES": "11",
            "ZH": "5"
          },
          "title": {
            "EN": "Where Are My Pets?",
            "ES": "¿Dónde están mis mascotas?",
            "ZH": "我的宠物在哪里？"
          },
          "level": {
            "EN": 1,
            "ES": 2,
            "ZH": 1
          },
          "lexile": {
            "EN": 70,
            "ES": 160,
            "ZH": 70
          },
          "fp": "B",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Each child has a different pet, lost in a different place.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "animals",
            "location/direction"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "dog",
            "rabbit",
            "cat",
            "bird",
            "fish",
            "pet",
            "tree"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Where Are My Pets (EN).pdf",
              "ES": "Proficiency Assessment-Where are my pets (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_WhereAreMyPets_Worksheet_EN.pdf",
              "ES": "SB_WhereAreMyPets_Worksheet_ES.pdf",
              "ZH": "SB_WhereAreMyPets_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Where Are My Pets.pdf"
            }
          }
        },
        {
          "set": 2,
          "path": "2-WhereAreYou_",
          "sortkey": {
            "EN": "1.01",
            "ES": "1.01",
            "ZH": "1.01",
            "VI": "1.01",
            "AR": "1.01",
            "KO": "1.01",
            "DR": "1.01",
            "PS": "1.01",
            "TL": "1.01",
            "BN": "1.01",
            "PT": "1.01",
            "HK": "1.01",
            "TW": "1.01"
          },
          "title": {
            "EN": "Where Are You?",
            "ES": "¿Dónde estás?",
            "ZH": "你在哪儿？",
            "VI": "Bạn ở đâu?",
            "AR": "أين أنت؟",
            "KO": "너는 어디에 있니?",
            "DR": "کجا استی؟",
            "PS": "ته چیرته یې؟",
            "TL": "Nasaan Ka?",
            "BN": "তুমি কোথায়?",
            "PT": "Onde Você Está?",
            "HK": "你在哪兒",
            "TW": "你在哪兒"
          },
          "level": {
            "EN": 1,
            "ES": 1,
            "ZH": 1,
            "VI": 1,
            "AR": 1,
            "KO": 1,
            "DR": 1,
            "PS": 1,
            "TL": 1,
            "BN": 1,
            "PT": 1,
            "HK": 1,
            "TW": 1
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "A game of hide and seek offers common words for locations.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "location/direction"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "high",
            "low",
            "near",
            "far",
            "in",
            "out"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-where are you EN.pdf",
              "ES": "Proficiency Assessment-where are you ES.pdf",
              "ZH": "",
              "VI": "",
              "AR": "",
              "KO": "",
              "DR": "",
              "PS": "",
              "TL": "",
              "BN": "",
              "PT": "",
              "HK": "",
              "TW": ""
            },
            "activity": {
              "EN": "SB_WhereAreYou_Worksheet_EN.pdf",
              "ES": "SB_WhereAreYou_Worksheet_ES.pdf",
              "ZH": "SB_WhereAreYou_Worksheet_ZH.pdf",
              "VI": "",
              "AR": "",
              "KO": "",
              "DR": "",
              "PS": "",
              "TL": "",
              "BN": "",
              "PT": "",
              "HK": "",
              "TW": ""
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Where Are You.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-CookingWithDad",
          "sortkey": {
            "EN": "48",
            "ES": "33",
            "ZH": "12",
            "VI": "48",
            "AR": "48",
            "KO": "48",
            "DR": "48",
            "PS": "48",
            "TL": "48",
            "BN": "48",
            "PT": "48",
            "HK": "48",
            "TW": "48"
          },
          "title": {
            "EN": "Cooking with Dad",
            "ES": "Cocinando con Papá",
            "ZH": "跟爸爸一起做饭",
            "VI": "Nấu ăn với bố",
            "AR": "الطبخ مع أبي",
            "KO": "아빠랑 요리해요",
            "DR": "آشپزی با پدر",
            "PS": "د پلار سره پخول",
            "TL": "Pagluluto Kasama si Tatay",
            "BN": "বাবার সাথে রান্না",
            "PT": "Cozinhando com o Pai",
            "HK": "跟爸爸一起做飯",
            "TW": "跟爸爸一起做飯"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 2,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "lexile": {
            "EN": 520,
            "ES": 500,
            "ZH": 520,
            "VI": 520,
            "AR": 520,
            "KO": 520,
            "DR": 520,
            "PS": 520,
            "TL": 520,
            "BN": 520,
            "PT": 520,
            "HK": 520,
            "TW": 520
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-PS",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW"
          ],
          "about": "Dad uses different utensils in the kitchen and makes a mess in process, but the food is delicious.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "daily life",
            "family",
            "food"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "pot",
            "pan",
            "spoon",
            "fork",
            "knife",
            "bowl"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Cooking With Dad (EN).pdf",
              "ES": "Proficiency Assessment-cooking with dad (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_CookingWithDad_Worksheet_EN.pdf",
              "ES": "SB_CookingWithDad_Worksheet_ES.pdf",
              "ZH": "SB_CookingWithDad_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Cooking With Dad.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-Let_sMakeAHat_",
          "sortkey": {
            "EN": "31",
            "ES": "10",
            "ZH": "22"
          },
          "title": {
            "EN": "Let's Make A Hat!",
            "ES": "¡Hagamos un sombrero!",
            "ZH": "我们来做帽子吧！"
          },
          "level": {
            "EN": 3,
            "ES": 2,
            "ZH": 2
          },
          "lexile": {
            "EN": 390,
            "ES": 150,
            "ZH": 390
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Kids make fun hats using typical art supplies and look great at the end.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "colors",
            "friends",
            "school"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "hat",
            "paper",
            "scissors",
            "tape",
            "glue",
            "pencil"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Lets Make A Hat (EN).pdf",
              "ES": "Proficiency Assessment-lets make a hat (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_LetsMakeAHat_Worksheet_EN.pdf",
              "ES": "SB_LetsMakeAHat_Worksheet_ES.pdf",
              "ZH": "SB_LetsMakeAHat_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Let_s Make a Hat.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-MyClothesDon_tFit_",
          "sortkey": {
            "EN": "5",
            "ES": "15",
            "ZH": "9",
            "VI": "5",
            "AR": "5",
            "KO": "5",
            "DR": "5",
            "PS": "5",
            "TL": "5",
            "BN": "5",
            "PT": "5",
            "HK": "5",
            "TW": "5",
            "PL": "5",
            "UK": "5"
          },
          "title": {
            "EN": "My Clothes Don't Fit!",
            "ES": "¡Mi ropa no me queda!",
            "ZH": "我的衣服不合身！",
            "VI": "Quần áo của tôi không vừa!",
            "AR": "ملابسي لا تناسبني!",
            "KO": "옷이 맞지 않아요!",
            "DR": "لباسهای من برابر نیستند!",
            "PS": "زما کالي مناسب نه دي!",
            "TL": "Hindi Sakto ang mga Damit Ko!",
            "BN": "আমার জামাকাপড় গায়ে লাগছে না!",
            "PT": "Minhas Roupas Não Servem!",
            "HK": "我的衣服不合身！",
            "TW": "我的衣服不合身！",
            "PL": "Moje ubrania nie pasują!",
            "UK": "Мій одяг не підходить!"
          },
          "level": {
            "EN": 1,
            "ES": 3,
            "ZH": 2,
            "VI": 1,
            "AR": 1,
            "KO": 1,
            "DR": 1,
            "TL": 1,
            "BN": 1,
            "PT": 1,
            "HK": 1,
            "TW": 1,
            "PL": 1,
            "UK": 1
          },
          "lexile": {
            "EN": 50,
            "ES": 320,
            "ZH": 50,
            "VI": 50,
            "AR": 50,
            "KO": 50,
            "DR": 50,
            "PS": 50,
            "TL": 50,
            "BN": 50,
            "PT": 50,
            "HK": 50,
            "TW": 50,
            "PL": 50,
            "UK": 50
          },
          "fp": "B",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PL",
            "EN-UK",
            "EN-PS"
          ],
          "about": "A girl tries on many clothes that don’t fit before finding the right outfit.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "clothes",
            "sizes",
            "daily life"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "shirt",
            "pants",
            "socks",
            "shoe",
            "coat",
            "hat"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - My Clothes Dont Fit (EN).pdf",
              "ES": "Proficiency Assessment-my clothes dont fit (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MyClothesDontFit_Worksheet_EN.pdf",
              "ES": "SB_MyClothesDontFit_Worksheet_ES.pdf",
              "ZH": "SB_MyClothesDontFit_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My Clothes Don_t Fit!.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-TheButterfly",
          "sortkey": {
            "EN": "29",
            "ES": "22",
            "ZH": "21"
          },
          "title": {
            "EN": "The Butterfly",
            "ES": "La mariposa",
            "ZH": "蝴蝶"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 2
          },
          "lexile": {
            "EN": 380,
            "ES": 430,
            "ZH": 380
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Follow a butterfly in search of a friend and learn about colors and emotions. Adapted from the story by Hans Christian Andersen.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "colors",
            "friends",
            "morals"
          ],
          "grammar": [
            "past"
          ],
          "keywords": [
            "sweet",
            "lonely",
            "big",
            "bright",
            "tired",
            "sad"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-the butterfly EN.pdf",
              "ES": "Proficiency Assessment-the butterfly (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheButterfly_Worksheet_EN.pdf",
              "ES": "SB_TheButterfly_Worksheet_ES.pdf",
              "ZH": "SB_TheButterfly_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Butterfly.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-TheLittleSeed",
          "sortkey": {
            "EN": "15",
            "ES": "17",
            "ZH": "29",
            "VI": "15",
            "AR": "15",
            "KO": "15",
            "DR": "15",
            "PS": "15",
            "TL": "15",
            "BN": "15",
            "PT": "15",
            "HK": "15",
            "TW": "15",
            "PL": "15",
            "UK": "15"
          },
          "title": {
            "EN": "The Little Seed",
            "ES": "La pequeña semilla",
            "ZH": "小种子",
            "VI": "Hạt giống nhỏ",
            "AR": "البذرة الصغيرة",
            "KO": "작은 씨앗",
            "DR": "دانه کوچک",
            "PS": "کوچنى تخم",
            "TL": "Ang Munting Buto",
            "BN": "ছোট্ট বীজ",
            "PT": "A Pequena Semente",
            "HK": "小種子",
            "TW": "小種子",
            "PL": "Małe nasionko",
            "UK": "Маленьке насіннячко"
          },
          "level": {
            "EN": 2,
            "ES": 3,
            "ZH": 3,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2,
            "PL": 2,
            "UK": 2
          },
          "lexile": {
            "EN": 220,
            "ES": 340,
            "ZH": 220,
            "VI": 220,
            "AR": 220,
            "KO": 220,
            "DR": 220,
            "PS": 220,
            "TL": 220,
            "BN": 220,
            "PT": 220,
            "HK": 220,
            "TW": 220,
            "PL": 220,
            "UK": 220
          },
          "fp": "G",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PL",
            "EN-UK",
            "EN-DR",
            "EN-PS"
          ],
          "about": "A little girl plants a tomato seed and proves to her disbelieving friends and family it will grow.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "daily life",
            "family",
            "food",
            "plants"
          ],
          "grammar": [
            "past",
            "future"
          ],
          "keywords": [
            "seed",
            "leaf",
            "tomatoes",
            "ground",
            "water",
            "to grow"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-little seed EN.pdf",
              "ES": "Proficiency Assessment-little seed ES.pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheLittleSeed_Worksheet_EN.pdf",
              "ES": "SB_TheLittleSeed_Worksheet_ES.pdf",
              "ZH": "SB_TheLittleSeed_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Little Seed.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-TheLizardAndTheSun",
          "sortkey": {
            "EN": "20",
            "ES": "23",
            "ZH": "31"
          },
          "title": {
            "EN": "The Lizard and the Sun",
            "ES": "La lagartija y el sol",
            "ZH": "蜥蜴与太阳"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 3
          },
          "lexile": {
            "EN": 330,
            "ES": 430,
            "ZH": 330
          },
          "fp": "J",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A famous Aztec tale about a lizard who keeps looking for the sun when all other animals give up.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "animals",
            "morals",
            "celebrations",
            "Earth"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "lizard",
            "sun",
            "bears",
            "fish",
            "birds",
            "festival"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-lizard and sun EN.pdf",
              "ES": "Proficiency Assessment-the lizard and the sun (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheLizardAndTheSun_Worksheet_EN.pdf",
              "ES": "SB_TheLizardAndTheSun_Worksheet_ES.pdf",
              "ZH": "SB_TheLizardAndTheSun_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Lizard and the Sun.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-TheRabbitAndTheTurtle",
          "sortkey": {
            "EN": "18",
            "ES": "19",
            "ZH": "20"
          },
          "title": {
            "EN": "The Rabbit and the Turtle",
            "ES": "El conejo y la tortuga",
            "ZH": "兔子和乌龟"
          },
          "level": {
            "EN": 2,
            "ES": 3,
            "ZH": 2
          },
          "lexile": {
            "EN": 280,
            "ES": 390,
            "ZH": 280
          },
          "fp": "I",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A rabbit challenges a brave turtle to a race in Aesop’s classic fable.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "animals",
            "sports"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "rabbit",
            "turtle",
            "race",
            "slow",
            "fast",
            "road"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-rabbit and turtle EN.pdf",
              "ES": "Proficiency Assessment-the rabbit and the turtle (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheRabbitAndTheTurtle_Worksheet_EN.pdf",
              "ES": "SB_TheRabbitAndTheTurtle_Worksheet_ES.pdf",
              "ZH": "SB_TheRabbitAndTheTurtle_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Rabbit and the Turtle.pdf"
            }
          }
        },
        {
          "set": 3,
          "path": "3-TheTree",
          "sortkey": {
            "EN": "39",
            "ES": "39",
            "ZH": "41"
          },
          "title": {
            "EN": "The Tree",
            "ES": "El árbol",
            "ZH": "树"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 4
          },
          "lexile": {
            "EN": 480,
            "ES": 520,
            "ZH": 480
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "As a little tree grows bigger it becomes a life-long companion for children as they grow up and a cycle of life continues.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "animals",
            "daily life",
            "family",
            "friends"
          ],
          "grammar": [
            "past"
          ],
          "keywords": [
            "tree",
            "seed",
            "nest",
            "house",
            "bird",
            "climb"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-the tree EN.pdf",
              "ES": "Proficiency Assessment-the tree (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheTree_Worksheet_EN.pdf",
              "ES": "SB_TheTree_Worksheet_ES.pdf",
              "ZH": "SB_TheTree_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Tree.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-AtTheZoo",
          "sortkey": {
            "EN": "11",
            "ES": "7",
            "ZH": "15",
            "VI": "11",
            "AR": "11",
            "KO": "11",
            "DR": "11",
            "PS": "11",
            "TL": "11",
            "BN": "11",
            "PT": "11",
            "HK": "11",
            "TW": "11"
          },
          "title": {
            "EN": "At the Zoo",
            "ES": "En el zoológico",
            "ZH": "在动物园",
            "VI": "Tại vườn thú",
            "AR": "في حديقة الحيوان",
            "KO": "동물원에서",
            "DR": "در باغ وحش",
            "PS": "په ژوبڼ کې",
            "TL": "Sa Zoo",
            "BN": "চিড়িয়াখানায়",
            "PT": "No Zoológico",
            "HK": "在動物園",
            "TW": "在動物園"
          },
          "level": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "lexile": {
            "EN": 150,
            "ES": 140,
            "ZH": 150,
            "VI": 150,
            "AR": 150,
            "KO": 150,
            "DR": 150,
            "PS": 150,
            "TL": 150,
            "BN": 150,
            "PT": 150,
            "HK": 150,
            "TW": 150
          },
          "fp": "E",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "A group of friends search for a little brother and find a lot of animals along the way.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "animals",
            "family",
            "friends",
            "location/direction"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "monkey",
            "snake",
            "giraffe",
            "tiger",
            "elephant",
            "lion"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - At The Zoo (EN).pdf",
              "ES": "Proficiency Assessment-at the zoo (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_AtTheZoo_Worksheet_EN.pdf",
              "ES": "SB_AtTheZoo_Worksheet_ES.pdf",
              "ZH": "SB_AtTheZoo_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_At the Zoo.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-FiveLittleMonkeys",
          "sortkey": {
            "EN": "58",
            "ES": "57",
            "ZH": "26"
          },
          "title": {
            "EN": "Five Little Monkeys",
            "ES": "Cinco monitos",
            "ZH": "五只小猴"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 3
          },
          "lexile": {
            "EN": 750,
            "ES": 800,
            "ZH": 750
          },
          "fp": "R",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Five monkeys jump on the bed and drive their mama crazy until one smart one remains.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "body",
            "health",
            "daily life",
            "family"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "monkey",
            "doctor",
            "jump",
            "bed",
            "head",
            "smart"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Five Little Monkeys (EN).pdf",
              "ES": "Proficiency Assessment-Five little monkeys (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_FiveLittleMonkeys_Worksheet_EN.pdf",
              "ES": "SB_FiveLittleMonkeys_Worksheet_ES.pdf",
              "ZH": "SB_FiveLittleMonkeys_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Five Little Monkeys.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-GetStrong_",
          "sortkey": {
            "EN": "4",
            "ES": "4",
            "ZH": "8",
            "VI": "4",
            "AR": "4",
            "KO": "4",
            "DR": "4",
            "PS": "4",
            "TL": "4",
            "BN": "4",
            "PT": "4",
            "HK": "4",
            "TW": "4"
          },
          "title": {
            "EN": "Get Strong!",
            "ES": "¡Fortalécete!",
            "ZH": "让身体更强壮！",
            "VI": "Hãy trở nên mạnh mẽ!",
            "AR": "!كُنْ قويًا",
            "KO": "튼튼하게!",
            "DR": "قوي شو!",
            "PS": "قوی شه!",
            "TL": "Magpalakas ka!",
            "BN": "শক্তিশালী হও!",
            "PT": "Fique Forte!",
            "HK": "讓身體更強壯！",
            "TW": "讓身體更強壯！"
          },
          "level": {
            "EN": 1,
            "ES": 1,
            "ZH": 2,
            "VI": 1,
            "AR": 1,
            "KO": 1,
            "DR": 1,
            "PS": 1,
            "TL": 1,
            "BN": 1,
            "PT": 1,
            "HK": 1,
            "TW": 1
          },
          "lexile": {
            "EN": -1,
            "ES": 70,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-PS",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW"
          ],
          "about": "An inspirational book that suggests getting exercise in many ways.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "body",
            "daily life",
            "friends"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "ride",
            "kick",
            "jump",
            "run",
            "dance",
            "play"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Get Strong (EN).pdf",
              "ES": "Proficiency Assessment-Get Strong (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_GetStrong_Worksheet_EN.pdf",
              "ES": "SB_GetStrong_Worksheet_ES.pdf",
              "ZH": "SB_GetStrong_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Get Strong.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-MyNeighborhood",
          "sortkey": {
            "EN": "16",
            "ES": "27",
            "ZH": "18"
          },
          "title": {
            "EN": "My Neighborhood",
            "ES": "Mi barrio",
            "ZH": "我的小区"
          },
          "level": {
            "EN": 2,
            "ES": 3,
            "ZH": 2
          },
          "lexile": {
            "EN": 240,
            "ES": 460,
            "ZH": 240
          },
          "fp": "H",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Practice location names as a little girl shows you around her neighborhood.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "daily life",
            "family",
            "friends",
            "school"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "across",
            "behind",
            "middle",
            "next door",
            "neighborhood",
            "around the corner"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - My Neighborhood (EN).pdf",
              "ES": "Proficiency Assessment-my neighborhood (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MyNeighborhood_Worksheet_EN.pdf",
              "ES": "SB_MyNeighborhood_Worksheet_ES.pdf",
              "ZH": "SB_MyNeighborhood_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My Neighborhood.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-MyNewHome",
          "sortkey": {
            "EN": "24",
            "ES": "24",
            "ZH": "11"
          },
          "title": {
            "EN": "My New Home",
            "ES": "Mi nuevo hogar",
            "ZH": "我的新家"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 2
          },
          "lexile": {
            "EN": 360,
            "ES": 430,
            "ZH": 360
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Learn about rooms and furniture in a puppy’s new home as he makes a mess of every room.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "daily life",
            "family",
            "home",
            "location/direction"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "home",
            "dining room",
            "living room",
            "bedroom",
            "bathroom",
            "kitchen"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - My New Home (EN).pdf",
              "ES": "Proficiency Assessment-my new home (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MyNewHome_Worksheet_EN.pdf",
              "ES": "SB_MyNewHome_Worksheet_ES.pdf",
              "ZH": "SB_MyNewHome_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My New Home.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-OurBody",
          "sortkey": {
            "EN": "43",
            "ES": "25",
            "ZH": "14"
          },
          "title": {
            "EN": "Our Body",
            "ES": "Nuestro cuerpo",
            "ZH": "我们的身体"
          },
          "level": {
            "EN": 4,
            "ES": 3,
            "ZH": 2
          },
          "lexile": {
            "EN": 500,
            "ES": 430,
            "ZH": 500
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Know your body and what each part can do.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "daily life",
            "food",
            "morals"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "ears",
            "mouth",
            "nose",
            "eyes",
            "legs",
            "arms"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-our body EN.pdf",
              "ES": "Proficiency Assessment-our body (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_OurBody_Worksheet_EN.pdf",
              "ES": "SB_OurBody_Worksheet_ES.pdf",
              "ZH": "SB_OurBody_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Our Body.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-Trash",
          "sortkey": {
            "EN": "21",
            "ES": "16",
            "ZH": "32",
            "VI": "21",
            "AR": "21",
            "KO": "21",
            "DR": "21",
            "PS": "21",
            "TL": "21",
            "BN": "21",
            "PT": "21",
            "HK": "21",
            "TW": "21"
          },
          "title": {
            "EN": "Trash",
            "ES": "Basura",
            "ZH": "垃圾",
            "VI": "Rác",
            "AR": "قمامة",
            "KO": "쓰레기",
            "DR": "زباله",
            "PS": "کثافات",
            "TL": "Basura",
            "BN": "আবর্জনা",
            "PT": "Lixo",
            "HK": "垃圾",
            "TW": "垃圾"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "lexile": {
            "EN": 330,
            "ES": 320,
            "ZH": 330,
            "VI": 330,
            "AR": 330,
            "KO": 330,
            "DR": 330,
            "PS": 330,
            "TL": 330,
            "BN": 330,
            "PT": 330,
            "HK": 330,
            "TW": 330
          },
          "fp": "J",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Reduce, reuse, recycle! Learn how a plastic bottle impacts the environment.",
          "genre": [
            "fiction: daily life",
            "non-fiction: science"
          ],
          "subjects": [
            "Earth",
            "daily life",
            "food",
            "morals"
          ],
          "grammar": [
            "present",
            "future"
          ],
          "keywords": [
            "bottle",
            "trash",
            "ocean",
            "thing",
            "water",
            "fish"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Trash (EN).pdf",
              "ES": "Proficiency Assessment-Trash (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_Trash_Worksheet_EN.pdf",
              "ES": "SB_Trash_Worksheet_ES.pdf",
              "ZH": "SB_Trash_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Trash.pdf"
            }
          }
        },
        {
          "set": 4,
          "path": "4-Wow_APotato_",
          "sortkey": {
            "EN": "9",
            "ES": "8",
            "ZH": "27",
            "VI": "9",
            "AR": "9",
            "KO": "9",
            "DR": "9",
            "PS": "9",
            "TL": "9",
            "BN": "9",
            "PT": "9",
            "HK": "9",
            "TW": "9"
          },
          "title": {
            "EN": "Wow! A Potato!",
            "ES": "¡Guau! ¡Una papa!",
            "ZH": "哇！土豆！",
            "VI": "Ồ! Khoai tây!",
            "AR": "رائع! بَطَاطِس!",
            "KO": "와! 감자다!",
            "DR": "واو! یک کچالو!",
            "PS": "واو! یو کچالو!",
            "TL": "Wow! Isang patatas!",
            "BN": "কি দারুন! আলু!",
            "PT": "Uau! Uma batata!",
            "HK": "哇！土豆！",
            "TW": "哇！土豆！"
          },
          "level": {
            "EN": 2,
            "ES": 2,
            "ZH": 3,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "lexile": {
            "EN": 120,
            "ES": 140,
            "ZH": 120,
            "VI": 120,
            "AR": 120,
            "KO": 120,
            "DR": 120,
            "PS": 120,
            "TL": 120,
            "BN": 120,
            "PT": 120,
            "HK": 120,
            "TW": 120
          },
          "fp": "D",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "A cheerful story that describes the lifecycle of a potato and favorite foods that come from potatoes.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "plants",
            "food"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "root",
            "seed",
            "potato",
            "leaf",
            "ground",
            "vine"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Wow A Potato (EN).pdf",
              "ES": "Proficiency Assessment-wow a potato (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_WowAPotato_Worksheet_EN.pdf",
              "ES": "SB_WowAPotato_Worksheet_ES.pdf",
              "ZH": "SB_WowAPotato_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Wow A Potato.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-AmazingOctopus_",
          "sortkey": {
            "EN": "57",
            "ES": "53",
            "ZH": "59"
          },
          "title": {
            "EN": "Amazing Octopus!",
            "ES": "¡Increíble pulpo!",
            "ZH": "神奇的章鱼！"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 720,
            "ES": 640,
            "ZH": 720
          },
          "fp": "Q",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Octopuses move, eat, and defend themselves in fascinating ways.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "food"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "octopus",
            "enemy",
            "heart",
            "blood",
            "ink",
            "arm",
            "shy"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Amazing Octopus (EN).pdf",
              "ES": "Proficiency Assessment-amazing octopus (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_AmazingOctopus_Worksheet_EN.pdf",
              "ES": "SB_AmazingOctopus_Worksheet_ES.pdf",
              "ZH": "SB_AmazingOctopus_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Amazing Octopus.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-AnimalsOfAfrica",
          "sortkey": {
            "EN": "59",
            "ES": "55",
            "ZH": "49"
          },
          "title": {
            "EN": "Animals of Africa",
            "ES": "Los animales de África",
            "ZH": "非洲动物"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 4
          },
          "lexile": {
            "EN": 800,
            "ES": 780,
            "ZH": 800
          },
          "fp": "S",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Ride along on safari to see predators and prey in their daily lives.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "travel"
          ],
          "grammar": [
            "present",
            "conditional"
          ],
          "keywords": [
            "elephant",
            "giraffe",
            "zebra",
            "hippopotamus",
            "leopard",
            "rhinoceros"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Animals of Africa (EN).pdf",
              "ES": "Proficiency Assessment-animals of africa (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_AnimalsOfAfrica_Worksheet_EN.pdf",
              "ES": "SB_AnimalsOfAfrica_Worksheet_ES.pdf",
              "ZH": "SB_AnimalsOfAfrica_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Animals of Africa.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-FantasticFlamingos",
          "sortkey": {
            "EN": "51",
            "ES": "47",
            "ZH": "50"
          },
          "title": {
            "EN": "Fantastic Flamingos",
            "ES": "Flamencos fantásticos",
            "ZH": "美丽的火烈鸟"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 4
          },
          "lexile": {
            "EN": 620,
            "ES": 600,
            "ZH": 620
          },
          "fp": "O",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A muddy white baby bird grows into a beautiful pink flamingo.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "food"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "mud, flamingo, feathers, snail shrimp, feed"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Fantastic Flamingos (EN).pdf",
              "ES": "Proficiency Assessment-fantastic flamingos (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_FantasticFlamingos_Worksheet_EN.pdf",
              "ES": "SB_FantasticFlamingos_Worksheet_ES.pdf",
              "ZH": "SB_FantasticFlamingos_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Fantastic Flamingos.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-SavingThePandas",
          "sortkey": {
            "EN": "49",
            "ES": "41",
            "ZH": "45",
            "VI": "49",
            "AR": "49",
            "KO": "49",
            "DR": "49",
            "PS": "49",
            "TL": "49",
            "BN": "49",
            "PT": "49",
            "HK": "49",
            "TW": "49"
          },
          "title": {
            "EN": "Saving the Pandas",
            "ES": "Salvando a los pandas",
            "ZH": "保护大熊猫",
            "VI": "Cứu lấy gấu trúc",
            "AR": "إنقاذ الباندا",
            "KO": "팬더 보호하기",
            "DR": "نجات دادن پانداها",
            "PS": "د پانداګان ژغورل",
            "TL": "Pangalagaan ang mga Panda",
            "BN": "পান্ডাদের রক্ষা করা",
            "PT": "Salvando os Pandas",
            "HK": "保護大熊貓",
            "TW": "保護大熊貓"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "lexile": {
            "EN": 540,
            "ES": 540,
            "ZH": 540,
            "VI": 540,
            "AR": 540,
            "KO": 540,
            "DR": 540,
            "PS": 540,
            "TL": 540,
            "BN": 540,
            "PT": 540,
            "HK": 540,
            "TW": 540
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Learn about what giant pandas eat, how they became endangered, and what humans are doing to help.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "food"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "panda",
            "bamboo",
            "to eat",
            "to save",
            "world",
            "hand"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Saving the Pandas (EN).pdf",
              "ES": "Proficiency Assessment-saving the pandas (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_SavingThePandas_Worksheet_EN.pdf",
              "ES": "SB_SavingThePandas_Worksheet_ES.pdf",
              "ZH": "SB_SavingThePandas_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Saving the Pandas.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-SeaTurtles",
          "sortkey": {
            "EN": "52",
            "ES": "49",
            "ZH": "51"
          },
          "title": {
            "EN": "Sea Turtles",
            "ES": "Tortugas marinas",
            "ZH": "海龟"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 4
          },
          "lexile": {
            "EN": 620,
            "ES": 610,
            "ZH": 620
          },
          "fp": "O",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Discover the life of sea turtles and what incredible swimmers they are.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "sea turtle",
            "jellyfish",
            "beak",
            "plastic bag",
            "beach",
            "ocean"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-sea turtles EN.pdf",
              "ES": "Proficiency Assessment-sea turtles (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_SeaTurtles_Worksheet_EN.pdf",
              "ES": "SB_SeaTurtles_Worksheet_ES.pdf",
              "ZH": "SB_SeaTurtles_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Sea Turtles.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-Sharks",
          "sortkey": {
            "EN": "54",
            "ES": "54",
            "ZH": "57"
          },
          "title": {
            "EN": "Sharks",
            "ES": "Tiburones",
            "ZH": "鲨鱼"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 680,
            "ES": 740,
            "ZH": 680
          },
          "fp": "P",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Sharks may seem scary, but they also face many challenges and deserve our respect.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "food"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "fin",
            "teeth",
            "soup",
            "stomach",
            "fisherman",
            "shark",
            "respect",
            "swallow"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Sharks (EN).pdf",
              "ES": "Proficiency Assessment-sharks (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_Sharks_Worksheet_EN.pdf",
              "ES": "SB_Sharks_Worksheet_ES.pdf",
              "ZH": "SB_Sharks_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Sharks.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-TheEmperorOfAntarctica",
          "sortkey": {
            "EN": "55",
            "ES": "50",
            "ZH": "58"
          },
          "title": {
            "EN": "The Emperor of Antarctica",
            "ES": "El emperador de la Antártida",
            "ZH": "南极帝企鹅"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 700,
            "ES": 620,
            "ZH": 700
          },
          "fp": "Q",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Learn how mom and dad Emperor penguins take turns raising their young in the freezing Antarctic.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "food"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "penguin",
            "egg",
            "Antarctica",
            "chick",
            "to freeze",
            "weight"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Emperor of Antarctica (EN).pdf",
              "ES": "Proficiency Assessment-emperor of antarctica (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheEmperorOfAntarctica_Worksheet_EN.pdf",
              "ES": "SB_TheEmperorOfAntarctica_Worksheet_ES.pdf",
              "ZH": "SB_TheEmperorOfAntarctica_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Emperor of Antarctica.pdf"
            }
          }
        },
        {
          "set": "5",
          "path": "5-WonderfulWhales",
          "sortkey": {
            "EN": "33",
            "ES": "21",
            "ZH": "25",
            "VI": "33",
            "AR": "33",
            "KO": "33",
            "DR": "33",
            "PS": "33",
            "TL": "33",
            "BN": "33",
            "PT": "33",
            "HK": "33",
            "TW": "33"
          },
          "title": {
            "EN": "Wonderful Whales",
            "ES": "Ballenas maravillosas",
            "ZH": "奇妙的鲸鱼",
            "VI": "Cá voi tuyệt vời",
            "AR": "حيتان مذهلة",
            "KO": "신비한 고래",
            "DR": "نهنگ های شگفت انگیز",
            "PS": "په زړه پورې نهنګونه",
            "TL": "Kahanga-hangang mga Balyena",
            "BN": "বিস্ময়কর তিমি",
            "PT": "Baleias Maravilhosas",
            "HK": "奇妙的鯨魚",
            "TW": "奇妙的鯨魚"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "lexile": {
            "EN": 420,
            "ES": 420,
            "ZH": 420,
            "VI": 420,
            "AR": 420,
            "KO": 420,
            "DR": 420,
            "PS": 420,
            "TL": 420,
            "BN": 420,
            "PT": 420,
            "HK": 420,
            "TW": 420
          },
          "fp": "L",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Read about the world’s largest animal and their life at sea.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "food"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "the whale",
            "the fish",
            "to jump",
            "to breathe",
            "the earwax",
            "the blowhole"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-wonderful whales EN.pdf",
              "ES": "Proficiency Assessment-wonderful whales (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_WonderfulWhales_Worksheet_EN.pdf",
              "ES": "SB_WonderfulWhales_Worksheet_ES.pdf",
              "ZH": "SB_WonderfulWhales_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Wonderful Whales.pdf"
            }
          }
        },
        {
          "set": "A",
          "path": "A-ForPete_sSake",
          "title": {
            "EN": "For Pete's Sake",
            "ES": "Por el amor de Pedro",
            "ZH": "为了彼得"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {},
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "",
          "genre": [],
          "subjects": [],
          "grammar": [],
          "keywords": [],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {},
            "lesson_plan": {
              "EN": "Lesson_Plan_For Pete_s Sake.pdf"
            }
          },
          "content": "full-length"
        },
        {
          "set": "7",
          "path": "7-LuckyWords",
          "sortkey": {
            "EN": "53",
            "ES": "60",
            "ZH": "52"
          },
          "title": {
            "EN": "Lucky Words",
            "ES": "Palabras de buena suerte",
            "ZH": "吉祥话"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 620,
            "ES": 1040,
            "ZH": 620
          },
          "fp": "O",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Many traditional foods shared during Chinese New Year sound like New Year wishes! \"Lucky Words\" explains the rich traditions of Chinese culture and teaches useful Chinese New Year greetings along the way!",
          "genre": [
            "non-fiction: history"
          ],
          "subjects": [
            "holidays",
            "food"
          ],
          "grammar": [
            "present",
            "future"
          ],
          "keywords": [
            "fish",
            "extra",
            "rice cake",
            "apple",
            "peaceful",
            "orange",
            "good luck"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-LuckyWords (EN).pdf",
              "ES": "Proficiency Assessment-LuckyWords (ES).pdf"
            },
            "activity": {
              "EN": "SB_LuckyWords_Worksheet_EN.pdf",
              "ES": "SB_LuckyWords_Worksheet_ES.pdf",
              "ZH": "SB_LuckyWords_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Lucky Words.pdf"
            }
          }
        },
        {
          "set": "7",
          "path": "7-IsThatABat_",
          "sortkey": {
            "EN": "41",
            "ES": "48",
            "ZH": "55"
          },
          "title": {
            "EN": "Is That a Bat?",
            "ES": "¿Es eso un murciélago?",
            "ZH": "那是一只蝙蝠吗？"
          },
          "level": {
            "EN": 4,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 480,
            "ES": 600,
            "ZH": 480
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Fun facts about bats, the only mammal that can fly! We learn about what bats eat, how they “hear” and why they are so important to us.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "animals",
            "science"
          ],
          "grammar": [
            "present",
            "past",
            "conditional"
          ],
          "keywords": [
            "bird",
            "rat",
            "mammal",
            "insect",
            "bat",
            "lizard"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Is That A Bat (EN).pdf",
              "ES": "Proficiency Assessment-Is that a Bat (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_IsThatABat_Worksheet_EN.pdf",
              "ES": "SB_IsThatABat_Worksheet_ES.pdf",
              "ZH": "SB_IsThatABat_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Is That a Bat.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-DancingInTheWind",
          "sortkey": {
            "EN": "25",
            "ES": "31",
            "ZH": "34"
          },
          "title": {
            "EN": "Dancing in the Wind",
            "ES": "Bailando en el viento",
            "ZH": "在风中起舞"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 3
          },
          "lexile": {
            "EN": 360,
            "ES": 480,
            "ZH": 360
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A child’s kite just wants to fly in the wind. When the string breaks the child realizes that letting go can also bring happiness.",
          "genre": [
            "fiction: outdoor"
          ],
          "subjects": [
            "sports",
            "outdoor",
            "morals"
          ],
          "grammar": [
            "past"
          ],
          "keywords": [
            "to blow",
            "to dive",
            "to spin",
            "to break",
            "to pull",
            "to fly away"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Dancing In the Wind (EN).pdf",
              "ES": "Proficiency Assessment-dancing in the wind (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_DancingInTheWind_Worksheet_EN.pdf",
              "ES": "SB_DancingInTheWind_Worksheet_ES.pdf",
              "ZH": "SB_DancingInTheWind_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Dancing in the Wind.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-ALetterForSanta",
          "sortkey": {
            "EN": "40",
            "ES": "28",
            "ZH": "43"
          },
          "title": {
            "EN": "A Letter for Santa",
            "ES": "Una carta para Papá Noel",
            "ZH": "给圣诞老人的信"
          },
          "level": {
            "EN": 4,
            "ES": 3,
            "ZH": 4
          },
          "lexile": {
            "EN": 480,
            "ES": 460,
            "ZH": 480
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "On Christmas Eve, two children plan for the arrival of Santa and write him a letter. Early in the morning, they wonder if Santa arrived.",
          "genre": [
            "fiction: holidays"
          ],
          "subjects": [
            "holidays",
            "family"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "game",
            "money",
            "letter",
            "doll",
            "train",
            "picture"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - A Letter For Santa (EN).pdf",
              "ES": "Proficiency Assessment-a letter for santa (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_ALetterForSanta_Worksheet_EN.pdf",
              "ES": "SB_ALetterForSanta_Worksheet_ES.pdf",
              "ZH": "SB_ALetterForSanta_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_A Letter for Santa.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-MusicComesToEarth",
          "sortkey": {
            "EN": "28",
            "ES": "42",
            "ZH": "36",
            "VI": "28",
            "AR": "28",
            "KO": "28",
            "DR": "28",
            "PS": "28",
            "TL": "28",
            "BN": "28",
            "PT": "28",
            "HK": "28",
            "TW": "28"
          },
          "title": {
            "EN": "Music Comes to Earth",
            "ES": "La música viene a la Tierra",
            "ZH": "音乐来到地球",
            "VI": "Âm nhạc đến với Trái Đất",
            "AR": "الموسيقى تأتي إلى الأرض",
            "KO": "음악이 지구로 옵니다",
            "DR": "موسیقی به زمین می آید",
            "PS": "موسيقي ځمکې ته راځي",
            "TL": "Nang Dumating ang Musika sa Mundo",
            "BN": "সঙ্গীত পৃথিবীতে এলো",
            "PT": "A Música Chega à Terra",
            "HK": "音樂來到地球",
            "TW": "音樂來到地球"
          },
          "level": {
            "EN": 3,
            "ES": 4,
            "ZH": 4,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "lexile": {
            "EN": 380,
            "ES": 540,
            "ZH": 380,
            "VI": 380,
            "AR": 380,
            "KO": 380,
            "DR": 380,
            "PS": 380,
            "TL": 380,
            "BN": 380,
            "PT": 380,
            "HK": 380,
            "TW": 380
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-PS",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "A tale where the wind brings musicians from the sun to introduce music to the earth.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "music",
            "weather"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "musician",
            "earth",
            "sun",
            "wind",
            "sing"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-MusicComesToEarth EN.pdf",
              "ES": "Proficiency Assessment-MusicComesToEarth ES.pdf"
            },
            "activity": {
              "EN": "SB_MusicComesToEarth_Worksheet_EN.pdf",
              "ES": "SB_MusicComesToEarth_Worksheet_ES.pdf",
              "ZH": "SB_MusicComesToEarth_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Music_Comes_To_Earth.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-TheBirthdayParty",
          "sortkey": {
            "EN": "35",
            "ES": "20",
            "ZH": "39"
          },
          "title": {
            "EN": "The Birthday Party",
            "ES": "La fiesta de cumpleaños",
            "ZH": "生日派对"
          },
          "level": {
            "EN": 4,
            "ES": 3,
            "ZH": 4
          },
          "lexile": {
            "EN": 450,
            "ES": 390,
            "ZH": 450
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A boy helps make sure his little sister has a fun birthday party.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "celebrations",
            "colors",
            "shapes"
          ],
          "grammar": [
            "present",
            "future",
            "conditional"
          ],
          "keywords": [
            "birthday",
            "cake"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-TheBirthdayParty (EN).pdf",
              "ES": "Proficiency Assessment-TheBirthdayParty (ES).pdf"
            },
            "activity": {
              "EN": "SB_TheBirthdayParty_Worksheet_EN.pdf",
              "ES": "SB_TheBirthdayParty_Worksheet_ES.pdf",
              "ZH": "SB_TheBirthdayParty_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Birthday Party.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-WhatTimeIsIt_",
          "sortkey": {
            "EN": "14",
            "ES": "12",
            "ZH": "17"
          },
          "title": {
            "EN": "What Time Is It?",
            "ES": "¿Qué hora es?",
            "ZH": "现在几点了？"
          },
          "level": {
            "EN": 2,
            "ES": 2,
            "ZH": 2
          },
          "lexile": {
            "EN": 190,
            "ES": 160,
            "ZH": 190
          },
          "fp": "F",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Learn what a student does at different times during a typical school day",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "time",
            "school"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "time",
            "math",
            "science",
            "homework"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-WhatTimeIsIt (EN).pdf",
              "ES": "Proficiency Assessment-WhatTimeIsIt (ES).pdf"
            },
            "activity": {
              "EN": "SB_WhatTimeIsIt_Worksheet_EN.pdf",
              "ES": "SB_WhatTimeIsIt_Worksheet_ES.pdf",
              "ZH": "SB_WhatTimeIsIt_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_What Time Is It.pdf"
            }
          }
        },
        {
          "set": "7",
          "path": "7-MyTerribleDay_",
          "sortkey": {
            "EN": "13",
            "ES": "9",
            "ZH": "16"
          },
          "title": {
            "EN": "My Terrible Day!",
            "ES": "Mi día terrible",
            "ZH": "我糟糕的一天"
          },
          "level": {
            "EN": 2,
            "ES": 2,
            "ZH": 2
          },
          "lexile": {
            "EN": 170,
            "ES": 140,
            "ZH": 170
          },
          "fp": "E",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A kid gets up late for school and everything goes wrong until he realizes it is Sunday!",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "daily life",
            "days of the week"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "to find",
            "to miss",
            "to walk",
            "to trip",
            "to be able",
            "to lock",
            "to be late"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-My Terrible Day EN.pdf",
              "ES": "Proficiency Assessment-My Terrible Day (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MyTerribleDay_Worksheet_EN.pdf",
              "ES": "SB_MyTerribleDay_Worksheet_ES.pdf",
              "ZH": "SB_MyTerribleDay_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My Terrible Day.pdf"
            }
          }
        },
        {
          "set": "7",
          "path": "7-EveryDropOfWater",
          "sortkey": {
            "EN": "36",
            "ES": "43",
            "ZH": "40"
          },
          "title": {
            "EN": "Every Drop of Water",
            "ES": "Cada gota de agua",
            "ZH": "每一滴水"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 4
          },
          "lexile": {
            "EN": 460,
            "ES": 540,
            "ZH": 460
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "All about water: who uses water, what is water made of, when do we use water, where do we find water, and why should we save it.",
          "genre": [
            "non-fiction: science"
          ],
          "subjects": [
            "water",
            "Earth",
            "science"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "water",
            "ice",
            "solid",
            "liquid",
            "gas",
            "steam"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Every Drop of Water (EN).pdf",
              "ES": "Proficiency Assessment-Every Drop of Water (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_EveryDropOfWater_Worksheet_EN.pdf",
              "ES": "SB_EveryDropOfWater_Worksheet_ES.pdf",
              "ZH": "SB_EveryDropOfWater_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Every Drop of Water.pdf"
            }
          }
        },
        {
          "set": "7",
          "path": "7-MadeInChina",
          "sortkey": {
            "EN": "12",
            "ES": "36",
            "ZH": "28"
          },
          "title": {
            "EN": "Made in China",
            "ES": "Hecho en China",
            "ZH": "中国制造"
          },
          "level": {
            "EN": 2,
            "ES": 4,
            "ZH": 3
          },
          "lexile": {
            "EN": 170,
            "ES": 500,
            "ZH": 170
          },
          "fp": "E",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Many common things were originally made in China, one of the world’s oldest cultures. Find out what we can thank China for inventing.",
          "genre": [
            "non-fiction: history"
          ],
          "subjects": [
            "food",
            "objects"
          ],
          "grammar": [
            "past"
          ],
          "keywords": [
            "teapot",
            "silk",
            "chopsticks",
            "firecracker",
            "ice cream",
            "noodles"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - Made in China (EN).pdf",
              "ES": "Proficiency Assessment-Made in China (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_MadeInChina_Worksheet_EN.pdf",
              "ES": "SB_MadeInChina_Worksheet_ES.pdf",
              "ZH": "SB_MadeInChina_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Made in China.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-TheGoatAndTheVegetableGarden",
          "sortkey": {
            "EN": "27",
            "ES": "35",
            "ZH": "35"
          },
          "title": {
            "EN": "The Goat and the Vegetable Garden",
            "ES": "La cabra y el jardín de vegetales",
            "ZH": "山羊和菜园"
          },
          "level": {
            "EN": 3,
            "ES": 4,
            "ZH": 3
          },
          "lexile": {
            "EN": 370,
            "ES": 500,
            "ZH": 370
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A little ant is the hero of this traditional Spanish story about a farmer who tries to rid his garden of a hungry goat that is eating all his vegetables.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "food"
          ],
          "grammar": [
            "past",
            "future"
          ],
          "keywords": [
            "peppers",
            "beans",
            "potatoes",
            "tomatoes",
            "lettuce",
            "farmer"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - The Goat and the Vegetable Garden (EN).pdf",
              "ES": "Proficiency Assessment-The Goat and the Vegetable Garden (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheGoatAndTheVegetableGarden_Worksheet_EN.pdf",
              "ES": "SB_TheGoatAndTheVegetableGarden_Worksheet_ES.pdf",
              "ZH": "SB_TheGoatAndTheVegetableGarden_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Goat and the Vegetable Garden.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-FirstDayOfSchool",
          "sortkey": {
            "EN": "30",
            "ES": "34",
            "ZH": "37"
          },
          "title": {
            "EN": "First Day of School",
            "ES": "El primer día de clases",
            "ZH": "开学的第一天"
          },
          "level": {
            "EN": 3,
            "ES": 4,
            "ZH": 4
          },
          "lexile": {
            "EN": 380,
            "ES": 500,
            "ZH": 380
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "School routines, counting to 20, basic greetings, and doing homework are the themes in this fun back-to-school story.",
          "genre": [
            "fiction: daily life"
          ],
          "subjects": [
            "greetings",
            "numbers",
            "school"
          ],
          "grammar": [
            "present",
            "future"
          ],
          "keywords": [
            "classroom",
            "teacher",
            "notebook",
            "homework",
            "eraser",
            "pencil"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-first day of school EN.pdf",
              "ES": "Proficiency Assessment-first day of school (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_FirstDayOfSchool_Worksheet_EN.pdf",
              "ES": "SB_FirstDayOfSchool_Worksheet_ES.pdf",
              "ZH": "SB_FirstDayOfSchool_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The First Day of School.pdf"
            }
          }
        },
        {
          "set": "6",
          "path": "6-BigBrownBear",
          "sortkey": {
            "EN": "19",
            "ES": "26",
            "ZH": "30"
          },
          "title": {
            "EN": "The Big Brown Bear",
            "ES": "El gran oso café",
            "ZH": "大个子的棕熊"
          },
          "level": {
            "EN": 3,
            "ES": 3,
            "ZH": 3
          },
          "lexile": {
            "EN": 310,
            "ES": 430,
            "ZH": 310
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A big brown bear discovers that by helping others, you become a true friend.",
          "genre": [
            "fiction: predictable story"
          ],
          "subjects": [
            "morals",
            "friendship"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "fat",
            "skinny",
            "funny",
            "big",
            "little",
            "true"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment - The Big Brown Bear (EN).pdf",
              "ES": "Proficiency Assessment-Big Brown Bear (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_TheBigBrownBear_Worksheet_EN.pdf",
              "ES": "SB_TheBigBrownBear_Worksheet_ES.pdf",
              "ZH": "SB_TheBigBrownBear_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Big Brown Bear.pdf"
            }
          }
        },
        {
          "set": "7",
          "path": "7-SevenWondersOfOurNaturalWorld",
          "sortkey": {
            "EN": "60",
            "ES": "59",
            "ZH": "60"
          },
          "title": {
            "EN": "Seven Wonders of Our Natural World",
            "ES": "Las siete maravillas de nuestro mundo natural",
            "ZH": "自然界的七大奇观"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 800,
            "ES": 830,
            "ZH": 800
          },
          "fp": "S",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Wonders of nature can be found on every continent: high mountains, active volcanoes, deep canyons, colorful skies, beautiful harbors, powerful waterfalls and awesome seas.",
          "genre": [
            "non-fiction: geography"
          ],
          "subjects": [
            "the Earth",
            "geography"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "volcano",
            "waterfall",
            "canyon",
            "harbor",
            "mountain",
            "continent"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-SevenWonders (EN).pdf",
              "ES": "Proficiency Assessment-SevenWonders (ES).pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_SevenWondersOfOurNaturalWorld_Worksheet_EN.pdf",
              "ES": "SB_SevenWondersOfOurNaturalWorld_Worksheet_ES.pdf",
              "ZH": "SB_SevenWondersOfOurNaturalWorld_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Seven Wonders of Our Natural World.pdf"
            }
          }
        },
        {
          "set": "7",
          "path": "7-IGoToTheDoctor",
          "sortkey": {
            "EN": "17",
            "ES": "13",
            "ZH": "19"
          },
          "title": {
            "EN": "I Go to the Doctor",
            "ES": "Yo voy al médico",
            "ZH": "我去看医生"
          },
          "level": {
            "EN": 2,
            "ES": 2,
            "ZH": 2
          },
          "lexile": {
            "EN": 250,
            "ES": 250,
            "ZH": 250
          },
          "fp": "",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A young girl gets a tummy ache and goes to the doctor. After the doctor checks everything, she remembers the real reason her stomach hurts.",
          "genre": [
            "Fiction: daily life, science"
          ],
          "subjects": [
            "Body",
            "food",
            "daily life"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "Lung",
            "heart",
            "stomach",
            "ear",
            "forehead",
            "mouth"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-IGoToTheDoctor EN.pdf",
              "ES": "Proficiency Assessment-IGoToTheDoctor ES.pdf",
              "ZH": ""
            },
            "activity": {
              "EN": "SB_IGoToTheDoctor_Worksheet_EN.pdf",
              "ES": "SB_IGoToTheDoctor_Worksheet_ES.pdf",
              "ZH": "SB_IGoToTheDoctor_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_I Go to the Doctor.pdf"
            }
          }
        },
        {
          "set": "7",
          "path": "7-TheSnakeAndTheBird",
          "sortkey": {
            "EN": "34",
            "ES": "51",
            "ZH": "38"
          },
          "title": {
            "EN": "The Snake and the Bird",
            "ES": "La serpiente y el pájaro",
            "ZH": "蛇与小鸟"
          },
          "level": {
            "EN": 3,
            "ES": 5,
            "ZH": 4
          },
          "lexile": {
            "EN": 430,
            "ES": 640,
            "ZH": 430
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A man rescues a bird that is fighting a snake. Adapted from Aesop’s Fables.",
          "genre": [
            "fiction: myth/legend"
          ],
          "subjects": [
            "animals",
            "morals"
          ],
          "grammar": [
            "past"
          ],
          "keywords": [
            "bird",
            "snake",
            "poison",
            "fight"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-Snake_Bird - (EN).pdf",
              "ES": "Proficiency Assessment-Snake_Bird (ES).pdf"
            },
            "activity": {
              "EN": "SB_TheSnakeAndTheBird_Worksheet_EN.pdf",
              "ES": "SB_TheSnakeAndTheBird_Worksheet_ES.pdf",
              "ZH": "SB_TheSnakeAndTheBird_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Snake and the Bird.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-Colors",
          "content": "word-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "Colors",
            "ES": "Colores",
            "ZH": "颜色",
            "VI": "Màu sắc",
            "AR": "الألوان",
            "KO": "색깔",
            "DR": "رنگها",
            "PS": "رنګونه",
            "TL": "Mga Kulay",
            "BN": "রং",
            "PT": "Cores",
            "HK": "顏色",
            "TW": "顏色"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-PS",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW"
          ],
          "about": "Different colors of the rainbow, plus grey, black, silver and gold.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Colors"
          ],
          "grammar": [
            "N/A"
          ],
          "keywords": [
            "Adjectives: red, orange, yellow, green, blue, indigo, purple, pink, white, gray, black, brown, silver, gold"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Colors_Worksheet_EN.pdf",
              "ES": "WB_Colors_Worksheet_ES.pdf",
              "ZH": "WB_Colors_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Colors.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-Numbers",
          "content": "word-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "Numbers",
            "ES": "Números",
            "ZH": "数字",
            "VI": "Những con số",
            "AR": "الأرقام",
            "KO": "숫자",
            "DR": "اعداد",
            "PS": "شمېرې",
            "TL": "Mga Numero",
            "BN": "সংখ্যা",
            "PT": "Números",
            "HK": "數字",
            "TW": "數字"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Numbers up to twenty, associating the written word with the numerical digit.",
          "genre": [
            "vocabulary",
            "counting"
          ],
          "subjects": [
            "Numbers"
          ],
          "grammar": [
            "N/A"
          ],
          "keywords": [
            "Nouns: numbers, zero, one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Numbers_Worksheet_EN.pdf",
              "ES": "WB_Numbers_Worksheet_ES.pdf",
              "ZH": "WB_Numbers_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Numbers.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-Feelings",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4
          },
          "content": "word-book",
          "title": {
            "EN": "Feelings",
            "ES": "Emociones",
            "ZH": "情绪"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A variety of feelings and emotions.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Feelings, emotions"
          ],
          "grammar": [
            "N/A"
          ],
          "keywords": [
            "Nouns: happy, sad, confident, scared, kind, jealous, grateful, angry, calm, confused, excited, worried, proud, sorry, surprised, disappointed, curious, bored, playful, lonely, brave, shy"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Feelings_Worksheet_EN.pdf",
              "ES": "WB_Feelings_Worksheet_ES.pdf",
              "ZH": "WB_Feelings_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Feelings.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-Balls",
          "content": "word-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6
          },
          "title": {
            "EN": "Balls",
            "ES": "Pelotas",
            "ZH": "球类"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different types of balls used in various sports.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Different types of balls and games"
          ],
          "grammar": [
            "N/A"
          ],
          "keywords": [
            "Nouns: basketball, baseball, American football, tennis ball, soccer ball, volleyball, lacrosse ball, field hockey ball, ping pong ball, golf ball, billiard ball, bowling ball"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Balls_Worksheet_EN.pdf",
              "ES": "WB_Balls_Worksheet_ES.pdf",
              "ZH": "WB_Balls_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Balls.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-Signs",
          "sortkey": {
            "EN": "22",
            "ES": "14",
            "ZH": "33"
          },
          "title": {
            "EN": "Signs",
            "ES": "Letreros",
            "ZH": "警示牌"
          },
          "level": {
            "EN": 3,
            "ES": 2,
            "ZH": 3
          },
          "lexile": {
            "EN": 360,
            "ES": 280,
            "ZH": 360
          },
          "fp": "K",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Signs can be annoying and tiresome, but it helps to know what they mean!",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "daily life",
            "location/direction"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "exit",
            "caution",
            "no crossing",
            "do not enter",
            "do not walk on grass",
            "wet paint"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-SIGNS - EN.pdf",
              "ES": "Proficiency Assessment-Signs ES.pdf"
            },
            "activity": {
              "EN": "SB_Signs_Worksheet_EN.pdf",
              "ES": "SB_Signs_Worksheet_ES.pdf",
              "ZH": "SB_Signs_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Signs.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-BeingAChiefA",
          "sortkey": {
            "EN": "44",
            "ES": "45",
            "ZH": "47"
          },
          "title": {
            "EN": "Being a Chief",
            "ES": "Ser un jefe",
            "ZH": "成为领导者"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 4
          },
          "lexile": {
            "EN": 510,
            "ES": 560,
            "ZH": 560
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Good leaders in our communities show how all of us can serve as a “chief.”",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "community",
            "helpers",
            "daily life",
            "morals"
          ],
          "grammar": [
            "present"
          ],
          "keywords": [
            "fire chief",
            "chief of a hospital",
            "business leader",
            "principal",
            "mayor",
            "student"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-BeingAChief (ES).pdf",
              "ES": "Proficiency Assessment-BeingAChiefA EN .pdf"
            },
            "activity": {
              "EN": "SB_BeingAChief_Worksheet_EN.pdf",
              "ES": "SB_BeingAChief_Worksheet_ES.pdf",
              "ZH": "SB_BeingAChief_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Being A Chief.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-TheMagicPaintbrush",
          "sortkey": {
            "EN": "37",
            "ES": "44",
            "ZH": "42",
            "VI": "37",
            "AR": "37",
            "KO": "37",
            "DR": "37",
            "PS": "37",
            "TL": "37",
            "BN": "37",
            "PT": "37",
            "HK": "37",
            "TW": "37"
          },
          "title": {
            "EN": "The Magic Paintbrush (A Story from China)",
            "ES": "El pincel mágico (Una historia de China)",
            "ZH": "神笔马良（中国故事）",
            "VI": "Chiếc Cọ Vẽ Thần Kỳ",
            "AR": "فرشاة الرسم السحرية",
            "KO": "마법의 붓",
            "DR": "برس نقاشی جادویی",
            "PS": "د جادو نقاشۍ برش",
            "TL": "Ang Mahiwagang Pinsel",
            "BN": "জাদুর তুলি (দ্য ম্যাজিক পেইন্টব্রাশ)",
            "PT": "O Pincel Mágico",
            "HK": "神筆馬良（中國故事）",
            "TW": "神筆馬良（中國故事）"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "lexile": {
            "EN": 480,
            "ES": 540,
            "ZH": 480,
            "VI": 480,
            "AR": 480,
            "KO": 480,
            "DR": 480,
            "PS": 480,
            "TL": 480,
            "BN": 480,
            "PT": 480,
            "HK": 480,
            "TW": 480
          },
          "fp": "M",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "A young artist uses a magic paintbrush to help everyone in need and finds a clever way to say good bye to a greedy man.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "morals",
            "daily life"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
            "mountain paintbrush",
            "ship",
            "village",
            "sea",
            "river"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-TheMagicPaintbrush EN.pdf",
              "ES": "Proficiency Assessment-TheMagicPaintbrush ES.pdf"
            },
            "activity": {
              "EN": "SB_TheMagicPaintbrushaStoryFromChina_Worksheet_EN.pdf",
              "ES": "SB_TheMagicPaintbrushaStoryFromChina_Worksheet_ES.pdf",
              "ZH": "SB_TheMagicPaintbrushaStoryFromChina_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_The Magic Paintbrush.pdf"
            }
          }
        },
        {
          "set": "8",
          "path": "8-SilverBells",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "Silver Bells (A Story from Spain)",
            "ES": "Campanas de plata (Una historia de España)",
            "ZH": "银铃响叮当（西班牙的故事）"
          },
          "level": {
            "EN": 4,
            "ES": 4,
            "ZH": 4
          },
          "lexile": {
            "EN": 510,
            "ES": 560,
            "ZH": 560
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Through their kindness and generosity, two poor boys discover the secret to making silver bells ring again.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "holiday",
            "morals",
            "values"
          ],
          "grammar": [
            "present",
            "past",
            "past perfect",
            "future"
          ],
          "keywords": [
            "deed",
            "bell",
            "gift",
            "pennies",
            "church",
            "heart"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Proficiency Assessment-SilverBells EN.pdf",
              "ES": "Proficiency Assessment-SilverBells ES .pdf"
            },
            "activity": {
              "EN": "SB_SilverBellsaStoryFromSpain_Worksheet_EN.pdf",
              "ES": "SB_SilverBellsaStoryFromSpain_Worksheet_ES.pdf",
              "ZH": "SB_SilverBellsaStoryFromSpain_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Silver Bells.pdf"
            }
          }
        },
        {
          "set": "9",
          "path": "9-MightyMonarchs_",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "Mighty Monarchs",
            "ES": "Monarcas Majestuosas",
            "ZH": "超级帝王蝶"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 920,
            "ES": 840,
            "ZH": 0
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The amazing story of how four generations of monarchs migrate every year from Mexico to Canada and then return to the same trees of their ancestors.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "nature",
            "lifecycle",
            "migration",
            "metamorphosis",
            "insects"
          ],
          "grammar": [
            "present",
            "past",
            "past perfect",
            "future"
          ],
          "keywords": [
            "generation",
            "pupa",
            "caterpillar",
            "migration",
            "nectar",
            "metamorphosis"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Mighty Monarchs (EN) - Running Record Assessment.pdf",
              "ES": "Mighty Monarchs (ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_MightyMonarchs_Worksheet_EN.pdf",
              "ES": "SB_MightyMonarchs_Worksheet_ES.pdf",
              "ZH": "SB_MightyMonarchs_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "9",
          "path": "9-TheTinyTadpole",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "The Tiny Tadpoles",
            "ES": "Los diminutos renacuajos",
            "ZH": "小蝌蚪"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 630,
            "ES": 720,
            "ZH": 0
          },
          "fp": "O",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The lifecycle of a frog gets a jumpstart as we learn how tadpoles are born and then grow up to eventually become frogs.",
          "genre": [
            "non-fiction"
          ],
          "subjects": [
            "animals",
            "lifecycle"
          ],
          "grammar": [
            "present",
            "past",
            "past perfect",
            "future"
          ],
          "keywords": [
    "predator", "metamorphosis", "egg mass", "tadpole", "to catch", "to guard", "pond", "frog"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "The Tiny Tadpoles (EN) - Running Record Assessment.pdf",
              "ES": "The Tiny Tadpoles (ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_TheTinyTadpole_Worksheet_EN.pdf",
              "ES": "SB_TheTinyTadpole_Worksheet_ES.pdf",
              "ZH": "SB_TheTinyTadpole_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "9",
          "path": "9-DayoftheDead",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "Day of Dead",
            "ES": "El día de los muertos",
            "ZH": "亡灵节"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 850,
            "ES": 770,
            "ZH": 0
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Experience one of the most important holidays in Mexico through the eyes of a young family member.",
          "genre": [
            "Fiction: culture"
          ],
          "subjects": [
            "culture",
            "festivals"
          ],
          "grammar": [
            "present",
            "past perfect"
          ],
          "keywords": [
            "cemetery",
            "candle",
            "ancestor",
            "altar",
            "decoration",
            "marigold",
            "skeleton",
            "grave"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Day of the Dead (EN) - Running Record Assessment.pdf",
              "ES": "Day of the Dead (ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_DayoftheDead_Worksheet_EN.pdf",
              "ES": "SB_DayoftheDead_Worksheet_ES.pdf",
              "ZH": "SB_DayoftheDead_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "9",
          "path": "9-BusyBees",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "Busy Bees",
            "ES": "Abejas trabajadoras",
            "ZH": "忙碌的蜜蜂"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 910,
            "ES": 860,
            "ZH": 0
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": " The complex lives of bees come alive as one “busy bee” takes us through her daily tasks and shows us around the hive.",
          "genre": [
            "non-fiction"
          ],
          "subjects": [
            "nature",
            "lifecycle",
            "insects",
            "metamorphosis",
            "food"
          ],
          "grammar": [
            "present",
            "past",
            "past perfect",
            "future"
          ],
          "keywords": [
            "bee",
            "hive",
            "honey",
            "queen",
            "cocoon",
            "pollen",
            "honeycomb",
            "larva or larvae"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Busy Bees(EN) - Running Record Assessment.pdf",
              "ES": "Busy Bees(ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_BusyBees_Worksheet_EN.pdf",
              "ES": "SB_BusyBees_Worksheet_ES.pdf",
              "ZH": "SB_BusyBees_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "9",
          "path": "9-FourFriends",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "Four Friends",
            "ES": "Los cuatro amigos",
            "ZH": "四个好朋友"
          },
          "level": {
            "EN": 4,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 530,
            "ES": 660,
            "ZH": 0
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The power of friendship and cooperation brings peace, health, and abundance, in this ancient tale from the Himalayans.",
          "genre": [
            "Fiction: myth & legend"
          ],
          "subjects": [
            "cooperation",
            "friendship"
          ],
          "grammar": [
            "present",
            "past"
          ],
          "keywords": [
            "king",
            "village",
            "disease",
             "famine",
            "draught",
            "friendship",
            "seed",
            "soil"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Four friends(EN) - Running Record Assessment.pdf",
              "ES": "Four Friends(ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_FourFriends_Worksheet_EN.pdf",
              "ES": "SB_FourFriends_Worksheet_ES.pdf",
              "ZH": "SB_FourFriends_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "9",
          "path": "9-500Parasols",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "500 Parasols",
            "ES": "Quinientas sombrillas",
            "ZH": "五百遮阳伞"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 710,
            "ES": 660,
            "ZH": 0
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Children think about what would be their greatest gift and discover that magic happens when they join together.",
          "genre": [
            "fiction"
          ],
          "subjects": [
            "cooperation", 
            "generosity"
          ],
          "grammar": [
            "present",
            "past",
            "future"
          ],
          "keywords": [
             "to decide", 
             "to welcome", 
             "to protect", 
             "to make", 
             "to look", 
              "to join", 
              "to give", 
              "to visit"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "500 Parasols (EN) - Running Record Assessment.pdf",
              "ES": "500 Parasols (ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_500Parasols_Worksheet_EN.pdf",
              "ES": "SB_500Parasols_Worksheet_ES.pdf",
              "ZH": "SB_500Parasols_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "9",
          "path": "9-SuperSnakes",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "Super Snakes",
            "ES": "Super Serpientes",
            "ZH": "超级大蛇"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 640,
            "ES": 810,
            "ZH": 0
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Pythons are the longest snake in the world! Discover what makes this snake so extraordinary.",
          "genre": [
            "Non-fiction"
          ],
          "subjects": [
            "nature",
            "animals",
            "lifecycle",
            "habitat"
          ],
          "grammar": [
            "present"
           
          ],
          "keywords": [
            "to squeeze",
            "to swallow",
            "to coil",
            "to hatch",
            "tongue",
            "camouflage",
            "jaw",
            "victim"
           
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Super Snakes (EN) - Running Record Assessment.pdf",
              "ES": "Super Snakes (ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_SuperSnakes_Worksheet_EN.pdf",
              "ES": "SB_SuperSnakes_Worksheet_ES.pdf",
              "ZH": "SB_SuperSnakes_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "9",
          "path": "9-WatchingForWolves",
          "sortkey": {
            "EN": "45",
            "ES": "46",
            "ZH": "48"
          },
          "title": {
            "EN": "Watching For Wolves",
            "ES": "Cuidado con los lobos",
            "ZH": "关注狼群"
          },
          "level": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "lexile": {
            "EN": 840,
            "ES": 790,
            "ZH": 0
          },
          "fp": "N",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Look, listen, and learn about the fascinating lives of wolves including how they live, communicate, and hunt together.",
          "genre": [
            "Non-fiction"
          ],
          "subjects": [
            "animals",
            "lifecycle",
            "habitats"
    
          ],
          "grammar": [
            "present",
            "past",
            "past perfect",
            "conditional"
          ],
          "keywords": [
            "to howl",
            "to hunt",
            "to protect",
            "the pack",
           "the prey",
            "a litter",
            "pup",
           "wilderness"
    
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {
              "EN": "Watching for Wolves (EN) - Running Record Assessment .pdf",
              "ES": "Watching for Wolves (ES) - Running Record Assessment.pdf"
            },
            "activity": {
              "EN": "SB_WatchingForWolves_Worksheet_EN.pdf",
              "ES": "SB_WatchingForWolves_Worksheet_ES.pdf",
              "ZH": "SB_WatchingForWolves_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": ""
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-AAW-AnimaslAroundTheWorld",
          "content": "word-book",
          "wblevel": {
            "EN": 1,
            "ES": 1,
            "ZH": 1
          },
          "title": {
            "EN": "Animals Around the World",
            "ES": "Animales alrededor del mundo",
            "ZH": "世界各地的动物"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The verb \"to like\" and what different animals from various continents \"like to do.\"",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Animals, \"to like', infinitive verbs"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: monkey, lion, tiger, camel, giraffe, elephant, kangaroo, bear; Verbs: likes, to climb, to eat, to walk, to drink, to see, to play, to jump, to sleep"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_AnimalsAroundTheWorld_Worksheet_EN.pdf",
              "ES": "WB_AnimalsAroundTheWorld_Worksheet_ES.pdf",
              "ZH": "WB_AnimalsAroundTheWorld_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Animals Around the World.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-AHO-AtHome",
          "content": "word-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "At home",
            "ES": "En la casa",
            "ZH": "在家",
            "VI": "Ở nhà",
            "AR": "في المنزل",
            "KO": "집에서",
            "DR": "در خانه",
            "PS": "کور کې",
            "TL": "Sa Bahay",
            "BN": "বাড়িতে",
            "PT": "Em Casa",
            "HK": "在家",
            "TW": "在家"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW"
          ],
          "about": "Different objects from home and opposite adjectives.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Home objects, opposite adjectives"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: sofa, chair, bed, table, lamp, carpet, toilet, stove, refrigerator, sink, towel, home; Adjectives: wide, narrow, long, short, tall, old, new, hot, cold, wet, dry"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_AtHome_Worksheet_EN.pdf",
              "ES": "WB_AtHome_Worksheet_ES.pdf",
              "ZH": "WB_AtHome_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_At Home.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-BOD-Body",
          "content": "word-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "title": {
            "EN": "My Body",
            "ES": "Mi cuerpo",
            "ZH": "我的身体",
            "VI": "Cơ thể của tôi",
            "AR": "جسمي",
            "KO": "내 몸",
            "DR": "بدن من",
            "PS": "زما بدن",
            "TL": "Aking Katawan",
            "BN": "আমার শরীর",
            "PT": "Meu Corpo",
            "HK": "我的身體",
            "TW": "我的身體"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-PS",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW"
          ],
          "about": "Different parts of the body, using numbers to describe how many there are of each part.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Body, body parts"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: head, hand, foot, finger, toe, arm, leg, hands, feet, fingers, toes, arms, legs, body; Adjectives: one, two, ten, strong, weak; Verbs: to have, to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_MyBody_Worksheet_EN.pdf",
              "ES": "WB_MyBody_Worksheet_ES.pdf",
              "ZH": "WB_MyBody_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My Body.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-BRE-Breakfast",
          "content": "word-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "title": {
            "EN": "Breakfast",
            "ES": "El desayuno",
            "ZH": "早餐",
            "VI": "Bữa ăn sáng",
            "AR": "وجبة الإفطار",
            "KO": "아침식사",
            "DR": "صبحانه",
            "PS": "ناشته",
            "TL": "Almusal",
            "BN": "সকালের নাশতা",
            "PT": "Café da manhã",
            "HK": "早餐",
            "TW": "早餐"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Different types of breakfast foods and and how they taste.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Breakfast foods, \"to taste\""
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: egg, cereal, oatmeal, yogurt, bacon, donut, pancakes, bread; Adjectives: hot, cold, warm, cool, salty, sweet, good, bad"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Breakfast_Worksheet_EN.pdf",
              "ES": "WB_Breakfast_Worksheet_ES.pdf",
              "ZH": "WB_Breakfast_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Breakfast.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-BUG-Bugs",
          "content": "word-book",
          "wblevel": {
            "EN": 1,
            "ES": 1,
            "ZH": 1
          },
          "title": {
            "EN": "Bugs",
            "ES": "Los bichos",
            "ZH": "虫子"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different types of bugs and opposite adjectives",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Insects & spider, opposite adjectives"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: bugs, butterfly, bee, beetle, mosquito, spider, ant, grasshopper, fly; Adjectives: big, little, large, small, one, many, quiet, loud"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Bugs_Worksheet_EN.pdf",
              "ES": "WB_Bugs_Worksheet_ES.pdf",
              "ZH": "WB_Bugs_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Bugs.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-CLO-Clothes",
          "content": "word-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4
          },
          "title": {
            "EN": "Clothes",
            "ES": "La ropa",
            "ZH": "衣服"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Clothing in singular and plural forms\n",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Clothing, similar/different, \"to wear\""
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: clothes, shirt, sweater, glove, hat, dress, coat, pants, shorts, socks, shoes, colors, sizes, shirts, sweaters, gloves, hats, dresses, coats; Adjectives: same, different\n"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Clothes_Worksheet_EN.pdf",
              "ES": "WB_Clothes_Worksheet_ES.pdf",
              "ZH": "WB_Clothes_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Clothes.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-DES-Desserts",
          "content": "word-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3
          },
          "title": {
            "EN": "Desserts",
            "ES": "Los postres",
            "ZH": "甜点"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different types of desserts in singular and plural form.\n",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Desserts, food, candy"
          ],
          "grammar": [
            "Present/past"
          ],
          "keywords": [
            "Nouns: cake, pie, ice cream, cupcake, candy, cookie, desserts; Adjectives: one, two, three, four, five, six; Verbs: to have, to eat (past tense)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Desserts_Worksheet_EN.pdf",
              "ES": "WB_Desserts_Worksheet_ES.pdf",
              "ZH": "WB_Desserts_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Desserts.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-DIN-Dinner",
          "content": "word-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3
          },
          "title": {
            "EN": "Dinner",
            "ES": "La cena",
            "ZH": "晚餐"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different kinds of food typically eaten at dinner.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Dinner, food, taste"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: fish, salad, meat, chicken, vegetables, rice, soup, noodles, food; Adjectives: warm, hot, spicy, good; Verbs: to like, to love, to eat, to cut, to taste, to be"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Dinner_Worksheet_EN.pdf",
              "ES": "WB_Dinner_Worksheet_ES.pdf",
              "ZH": "WB_Dinner_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Dinner.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-DOW-DaysOfWeek",
          "content": "word-book",
          "wblevel": {
            "EN": 7,
            "ES": 7,
            "ZH": 7
          },
          "title": {
            "EN": "Days of the Week",
            "ES": "Los días de la semana",
            "ZH": "一周七天"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The days of the week.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Days of the week"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: Monday, Tuesday,  Wednesday, Thursday, Friday,  Saturday, Sunday, TV, bike, music, sports, days, week; Verbs: to want, to draw, to watch, to read, to ride, to play, to sleep"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_DaysOfTheWeek_Worksheet_EN.pdf",
              "ES": "WB_DaysOfTheWeek_Worksheet_ES.pdf",
              "ZH": "WB_DaysOfTheWeek_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Days of the Week.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-FAC-Face",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4
          },
          "content": "word-book",
          "title": {
            "EN": "Face",
            "ES": "La cara",
            "ZH": "脸"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The different parts of the face.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Face, body parts"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: face, eye, mouth, nose, hair, ear, teeth, eyes, ears; Adjectives: two, one, short, many"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Face_Worksheet_EN.pdf",
              "ES": "WB_Face_Worksheet_ES.pdf",
              "ZH": "WB_Face_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Face.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-FAN-FarmAnimals",
          "content": "word-book",
          "wblevel": {
            "EN": 1,
            "ES": 1,
            "ZH": 1,
            "VI": 1,
            "AR": 1,
            "KO": 1,
            "DR": 1,
            "PS": 1,
            "TL": 1,
            "BN": 1,
            "PT": 1,
            "HK": 1,
            "TW": 1,
            "PL": 1,
            "UK": 1
          },
          "title": {
            "EN": "Farm Animals",
            "ES": "Animales de la granja",
            "ZH": "农场动物",
            "VI": "Gia súc",
            "AR": "حيوانات المزرعة",
            "KO": "농장 동물",
            "DR": "حیوانات مزرعه",
            "PS": "د فارم حیوانات",
            "TL": "Mga Hayop sa Bukid",
            "BN": "খামারের পশুপাখি",
            "PT": "Animais de Fazenda",
            "HK": "農場動物",
            "TW": "農場動物",
            "PL": "Zwierzęta hodowlane",
            "UK": "Сільськогосподарські тварини"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0,
            "PL": 0,
            "UK": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1,
            "PL": -1,
            "UK": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PL",
            "EN-UK",
            "EN-PS"
          ],
          "about": "Different common farm animals.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Farm animals"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: farm animals, horse, sheep, cow, duck, pig, goat, chickens, turkey; Adjectives: fast, slow, large, small, dirty, clean, thin, fat; Verbs: to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_FarmAnimals_Worksheet_EN.pdf",
              "ES": "WB_FarmAnimals_Worksheet_ES.pdf",
              "ZH": "WB_FarmAnimals_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Farm Animals.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-FFI-FantasyFigures",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2
          },
          "content": "word-book",
          "title": {
            "EN": "Fantasy Figures",
            "ES": "Figuras fantásticas",
            "ZH": "虚幻人物"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different popular “fantasy figures”.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Fantasy figures"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: fantasy figures, unicorn, fairy, dragon, ghost, witch, zombies; Adjectives: purple, pink, red, white, green, gray; Verbs: to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_FantasyFigures_Worksheet_EN.pdf",
              "ES": "WB_FantasyFigures_Worksheet_ES.pdf",
              "ZH": "WB_FantasyFigures_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Fantasy Figures.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-FFO-FastFood",
          "content": "word-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "title": {
            "EN": "Fast Food",
            "ES": "Comida rápida",
            "ZH": "快餐",
            "VI": "Đồ ăn nhanh",
            "AR": "الوجبات السريعة",
            "KO": "패스트 푸드",
            "DR": "فست فود",
            "PS": "فاسټ فود",
            "TL": "Fast Food",
            "BN": "ঝটপট খাবার (ফাস্ট ফুড)",
            "PT": "Fast Food",
            "HK": "快餐",
            "TW": "快餐"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Different types of fast food.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Fast food, food"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: fast food, hamburger, sandwich, french fries, pizza, fried chicken, hot dog; Adjectives: big, small, salty, spicy, hot, cold "
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_FastFood_Worksheet_EN.pdf",
              "ES": "WB_FastFood_Worksheet_ES.pdf",
              "ZH": "WB_FastFood_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Fast Food.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-FRU-Fruit",
          "content": "word-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3
          },
          "title": {
            "EN": "Fruit",
            "ES": "La fruta",
            "ZH": "水果"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A variety of common fruit.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Fruit, food"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: fruit, lemon, watermelon, coconut, strawberry, pineapple, plums, orange, cherry. Adjectives: sour, sweet, big, small, yellow, purple, orange, red; Verbs: is, are"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Fruit_Worksheet_EN.pdf",
              "ES": "WB_Fruit_Worksheet_ES.pdf",
              "ZH": "WB_Fruit_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Fruit.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-HAB-Habitats",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "content": "word-book",
          "title": {
            "EN": "Habitats",
            "ES": "Los hábitats",
            "ZH": "栖息地"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different habitats, and where animals commonly live.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Habitats, animals"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: mountains, forest, ocean, desert, wetlands, grassland, South Pole, habitats, bears, deer, sharks, lizards, alligators, foxes, penguins"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Habitats_Worksheet_EN.pdf",
              "ES": "WB_Habitats_Worksheet_ES.pdf",
              "ZH": "WB_Habitats_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Habitats.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-JOB-Jobs",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6,
            "PL": 6,
            "UK": 6
          },
          "content": "word-book",
          "title": {
            "EN": "Jobs",
            "ES": "Trabajos",
            "ZH": "工作",
            "VI": "Công việc",
            "AR": "وظائف",
            "KO": "직업",
            "DR": "وظایف",
            "PS": "دندې",
            "TL": "Trabaho",
            "BN": "কাজ",
            "PT": "Empregos",
            "HK": "工作",
            "TW": "工作",
            "PL": "Zawody",
            "UK": "Професії"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0,
            "PL": 0,
            "UK": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1,
            "PL": -1,
            "UK": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PL",
            "EN-UK",
            "EN-PS"
          ],
          "about": "Common jobs that people do.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Jobs, adjectives"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: doctor, firefighter, dentist, farmer, teacher, waiter, scientist, business people; Adjectives: kind, brave, confident, proud, calm, happy, curious, excited; Verbs: to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Jobs_Worksheet_EN.pdf",
              "ES": "WB_Jobs_Worksheet_ES.pdf",
              "ZH": "WB_Jobs_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Jobs.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-MFA-MyFamily",
          "content": "word-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "title": {
            "EN": "My Family",
            "ES": "Mi familia",
            "ZH": "我的家人",
            "VI": "Gia đình của tôi",
            "AR": "عائلتي",
            "KO": "내 가족",
            "DR": "خانواده من",
            "PS": "زما کورنۍ",
            "TL": "Aking Pamilya",
            "BN": "আমার পরিবার",
            "PT": "Minha Família",
            "HK": "我的家人",
            "TW": "我的家人"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Relationships with members of the family.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Family, relationships"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: family, me, mother, father, sister, brother, uncle, aunt, baby, cousin, grandfather, grandmother, us, grandparents, dog, pet; Adjectives: little, big, other; Verbs: to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_MyFamily_Worksheet_EN.pdf",
              "ES": "WB_MyFamily_Worksheet_ES.pdf",
              "ZH": "WB_MyFamily_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_My Family.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-OWO-OurWorld",
          "content": "word-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "title": {
            "EN": "Our World",
            "ES": "Nuestro mundo",
            "ZH": "我们的世界"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Our planet and solar system and their qualities.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Planets, world, solar system, earth"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: Earth, ocean, mountains, sun, moon, land, trash, star, stars, planets, planet, world; Adjectives: round, deep, high, big, small, beautiful, ugly, many; Verbs: to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_OurWorld_Worksheet_EN.pdf",
              "ES": "WB_OurWorld_Worksheet_ES.pdf",
              "ZH": "WB_OurWorld_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Our World.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-PET-Pets",
          "wblevel": {
            "EN": 1,
            "ES": 1,
            "ZH": 1
          },
          "content": "word-book",
          "title": {
            "EN": "Pets",
            "ES": "Las mascotas",
            "ZH": "宠物"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different animals that could be pets, their qualities and how they behave.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Pets, Animals"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: pets, cat, dog, goldfish, bird, snake, turtle, rabbit, hamster; Adjectives: fat, thin, fast, slow, fast, first, last; Verbs: to be, to swim, to fly"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Pets_Worksheet_EN.pdf",
              "ES": "WB_Pets_Worksheet_ES.pdf",
              "ZH": "WB_Pets_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Pets.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-PLN-Plants",
          "content": "word-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "title": {
            "EN": "Plants",
            "ES": "Las plantas",
            "ZH": "植物"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different types of plants.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Plants, sizes"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: plants, tree, grass, flower, cactus, mushrooms, vine; Adjectives: tall, short, small, big, little, long; Verbs: to be (singuar/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Plants_Worksheet_EN.pdf",
              "ES": "WB_Plants_Worksheet_ES.pdf",
              "ZH": "WB_Plants_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Plants.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-RAT-RoomsAndThings",
          "content": "word-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "title": {
            "EN": "Rooms",
            "ES": "Las habitaciones",
            "ZH": "房间"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Familiar objects that belong in different rooms of a house.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Rooms, house, objects, furniture"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: living room, kitchen, dining room, study, bedroom, bathroom, door, window, rooms, sofa, stove, table, computer, bed, sink; Adjectives: closed, open"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Rooms_Worksheet_EN.pdf",
              "ES": "WB_Rooms_Worksheet_ES.pdf",
              "ZH": "WB_Rooms_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Rooms.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-SAN-SeaAnimals",
          "content": "word-book",
          "wblevel": {
            "EN": 1,
            "ES": 1,
            "ZH": 1,
            "VI": 1,
            "AR": 1,
            "KO": 1,
            "DR": 1,
            "PS": 1,
            "TL": 1,
            "BN": 1,
            "PT": 1,
            "HK": 1,
            "TW": 1
          },
          "title": {
            "EN": "Sea Animals",
            "ES": "Los animales marinos",
            "ZH": "海洋动物",
            "VI": "Động vật biển",
            "AR": "حيوانات بحرية",
            "KO": "바다 동물",
            "DR": "حیوانات دریایی",
            "PS": "بحری حیوانات",
            "TL": "Mga hayop sa dagat",
            "BN": "সাগরের প্রাণি",
            "PT": "Animais Marinhos",
            "HK": "海洋動物",
            "TW": "海洋動物"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Different types of sea animals and what they do.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Sea animals, animals"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: sea animals, sea turtle, fish, crab, dolphin, octopus, whale, shrimp, shark; Verbs: to swim, to hide, to walk, to play, to climb, to jump, to dig, to bite"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_SeaAnimals_Worksheet_EN.pdf",
              "ES": "WB_SeaAnimals_Worksheet_ES.pdf",
              "ZH": "WB_SeaAnimals_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Sea Animals.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-SCH-School",
          "content": "word-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "title": {
            "EN": "School",
            "ES": "El colegio",
            "ZH": "学校"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Commonly-used school-related objects.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "School, objects"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: school, book, pencil, scissors, glue, desk, eraser, paper, backpack, books, pencils, everything; Adjectives: many, few, strong, pink; Verbs: to have, to cut, to sit, to write, to be"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_School_Worksheet_EN.pdf",
              "ES": "WB_School_Worksheet_ES.pdf",
              "ZH": "WB_School_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_School.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-SEA-Seasons",
          "wblevel": {
            "EN": 7,
            "ES": 7,
            "ZH": 7
          },
          "content": "word-book",
          "title": {
            "EN": "Seasons",
            "ES": "Las estaciones",
            "ZH": "一年四季"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different seasons as well as common activities related to each season.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Seasons, activities"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: seasons, spring, fall, winter, summer, rainbow, hat, leaves, snowman; Adjectives: hot, windy; Verbs: to rain, to look, to be, to need, to jump, to snow, to make"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Seasons_Worksheet_EN.pdf",
              "ES": "WB_Seasons_Worksheet_ES.pdf",
              "ZH": "WB_Seasons_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Seasons.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-SHA-Shapes",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2
          },
          "content": "word-book",
          "title": {
            "EN": "Shapes",
            "ES": "Las figuras geométricas",
            "ZH": "形状"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different shapes and relating them to familiar objects.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Shapes, objects"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: square, triangle, diamond, rectangle, star, circle, cross, house, roof, window, door, flag, wheels, road; Adjectives: square; Verbs: to have, to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Shapes_Worksheet_EN.pdf",
              "ES": "WB_Shapes_Worksheet_ES.pdf",
              "ZH": "WB_Shapes_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Shapes.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-TEC-Technology",
          "wblevel": {
            "EN": 8,
            "ES": 8,
            "ZH": 8
          },
          "content": "word-book",
          "title": {
            "EN": "Technology",
            "ES": "La tecnología",
            "ZH": "科技"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different types of commonly-used technology.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Technology"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: computer, phone, tablet, television, internet, plug, everything; Verbs: to write, to talk, to read, to watch, to learn, to need"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Technology_Worksheet_EN.pdf",
              "ES": "WB_Technology_Worksheet_ES.pdf",
              "ZH": "WB_Technology_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Technology.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-TRA-Transit",
          "content": "word-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5
          },
          "title": {
            "EN": "Transportation",
            "ES": "El transporte",
            "ZH": "交通工具"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different types of common modes of transportation with adjectives.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Transportation"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: transportation, bicycle, boat, airplane, car, truck, train, bus, motorcycle, taxi; Adjectives: small, big, long, short, fast, slow; Verbs: to ride, to go, to fly"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Transportation_Worksheet_EN.pdf",
              "ES": "WB_Transportation_Worksheet_ES.pdf",
              "ZH": "WB_Transportation_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Transportation.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-VEG-Vegetables",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3
          },
          "content": "word-book",
          "title": {
            "EN": "Vegetables",
            "ES": "Los vegetales",
            "ZH": "蔬菜"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A variety of common vegetables and related colors",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Vegetables"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: vegetables, corn, lettuce, tomato, peppers, potato, beans, onion, pumpkin, peas, eggplant; Adjectives: yellow, green, red, brown, black, white, orange, green, purple; to be (singular/plural) "
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Vegetables_Worksheet_EN.pdf",
              "ES": "WB_Vegetables_Worksheet_ES.pdf",
              "ZH": "WB_Vegetables_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Vegetables.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-WAN-WildAnimals",
          "wblevel": {
            "EN": 1,
            "ES": 1,
            "ZH": 1
          },
          "content": "word-book",
          "title": {
            "EN": "Wild Animals",
            "ES": "Animales salvajes",
            "ZH": "野生动物"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Different wild animals and what they do.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Wild animals, animals"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: wild animals, bat, squirrel, frog, fox, snake, deer, mouse, lizard; Adverb: quiet; Verbs: to fly, to eat, to jump, to hide, to bite, to run, to listen"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_WildAnimals_Worksheet_EN.pdf",
              "ES": "WB_WildAnimals_Worksheet_ES.pdf",
              "ZH": "WB_WildAnimals_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Wild Animals.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-HOB-Hobbies",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "content": "word-book",
          "title": {
            "EN": "Hobbies",
            "ES": "Los pasatiempos",
            "ZH": "爱好",
            "VI": "Sở thích",
            "AR": "هوايات",
            "KO": "취미",
            "DR": "سرگرمی ها",
            "PS": "مصروفیتونه",
            "TL": "Libangan",
            "BN": "শখ",
            "PT": "Hobbies",
            "HK": "愛好",
            "TW": "愛好"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Different hobbies or activities kids like to do.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Hobbies"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: bike, morning, afternoon, evening, music, night, week, friends, summer, family, sports, weekends, video games, day; Verbs: ride, draw, listen, read, sing, travel, play"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Hobbies_Worksheet_EN.pdf",
              "ES": "WB_Hobbies_Worksheet_ES.pdf",
              "ZH": "WB_Hobbies_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Hobbies.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-PLA-Places",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "content": "word-book",
          "title": {
            "EN": "Places",
            "ES": "Lugares",
            "ZH": "地方",
            "VI": "Nơi chốn",
            "AR": "أماكن",
            "KO": "장소",
            "DR": "مکان ها",
            "PS": "ځايونه",
            "TL": "Mga Lugar",
            "BN": "স্থানসমুহ",
            "PT": "Locais",
            "HK": "地方",
            "TW": "地方"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH",
            "EN-VI",
            "EN-AR",
            "EN-KO",
            "EN-DR",
            "EN-TL",
            "EN-BN",
            "EN-PT",
            "EN-HK",
            "EN-TW",
            "EN-PS"
          ],
          "about": "Different common places in the community",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Places, locations"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: school, store, park, library, theater, bank, hospital, restaurant, gift, ticket, money; Adjectives: open, closed; Verbs: to learn, to buy, to play, to read, to get, to have, to be"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Places_Worksheet_EN.pdf",
              "ES": "WB_Places_Worksheet_ES.pdf",
              "ZH": "WB_Places_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Places.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-TOO-Tools",
          "wblevel": {
            "EN": 8,
            "ES": 8,
            "ZH": 8
          },
          "content": "word-book",
          "title": {
            "EN": "Tools",
            "ES": "Las herramientas",
            "ZH": "工具"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "A variety of commonly-used tools.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Tools, objects"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: saw, ladder, ruler, flashlight, hammer, nails, things, tools; Adjectives: many; Verbs: to use, to cut, to climb, to need, to draw, to find, to work, to have"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Tools_Worksheet_EN.pdf",
              "ES": "WB_Tools_Worksheet_ES.pdf",
              "ZH": "WB_Tools_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Tools.pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-YYA-Adjective1",
          "content": "word-book",
          "sortkey": {
            "EN": "2",
            "ES": "2",
            "ZH": "2"
          },
          "wblevel": {
            "EN": 9,
            "ES": 9,
            "ZH": 9
          },
          "title": {
            "EN": "Sizes (Adjectives)",
            "ES": "Los tamaños (adjetivos)",
            "ZH": "尺寸(形容词)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Common adjectives describe different sizes of animals and common household objects.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Sizes, animals, objects"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: cow, duck, cat, dog, bed, table, sofa, chair, mountains, ocean; Adjectives: large, small, fat, thin, long, short, wide, narrow, high, deep; Verbs: to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Sizesadjectives_Worksheet_EN.pdf",
              "ES": "WB_Sizesadjectives_Worksheet_ES.pdf",
              "ZH": "WB_Sizesadjectives_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Sizes (Adjectives).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-YYB-Adjective2",
          "content": "word-book",
          "sortkey": {
            "EN": "3",
            "ES": "3",
            "ZH": "3"
          },
          "wblevel": {
            "EN": 9,
            "ES": 9,
            "ZH": 9
          },
          "title": {
            "EN": "Tastes (Adjectives)",
            "ES": "Los gustos (adjetivos)",
            "ZH": "味道(形容词)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Common adjectives describe qualities of different types of foods.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Food"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: pancakes, bread, donut, bacon, noodles, lemons, egg, cereal, oatmeal, yogurt; Adjectives: good, bad, sweet, salty, spicy, sour, hot, cold, warm, cool; Verbs:to taste, to be (singular/plural) "
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Tastesadjectives_Worksheet_EN.pdf",
              "ES": "WB_Tastesadjectives_Worksheet_ES.pdf",
              "ZH": "WB_Tastesadjectives_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Tastes (Adjectives).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-YYC-Adjective3",
          "sortkey": {
            "EN": "4",
            "ES": "4",
            "ZH": "4"
          },
          "wblevel": {
            "EN": 9,
            "ES": 9,
            "ZH": 9
          },
          "content": "word-book",
          "title": {
            "EN": "Things (Adjectives)",
            "ES": "Las cosas (adjetivos)",
            "ZH": "东西(形容词)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Common adjectives describe a variety of common household objects.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Things, objects, household"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: toilet, carpet, sink, towels, shoes, shirts, land, trash, door, window; Adjectives: new, old, wet, dry, different, same, beautiful, ugly, open, closed; Verbs: to be (singular/plural)"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_Thingsadjectives_Worksheet_EN.pdf",
              "ES": "WB_Thingsadjectives_Worksheet_ES.pdf",
              "ZH": "WB_Thingsadjectives_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Things (Adjectives).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-YYD-Adjective4",
          "content": "word-book",
          "sortkey": {
            "EN": 1,
            "ES": 1,
            "ZH": 1
          },
          "wblevel": {
            "EN": 9,
            "ES": 9,
            "ZH": 9
          },
          "title": {
            "EN": "Animals and People (Adjectives)",
            "ES": "Los animales y las personas (adjetivos)",
            "ZH": "动物和人(形容词)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "Common adjectives describe qualities of people and animals",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Animals, body parts, opposite adjectives"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: arms, legs, horse, turtle, pig, goat, ants, bees, flies, mouse; Adjectives: strong, weak, fast, slow, dirty, clean, many, few, loud, quiet; Verbs: to be, to like, to wear"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_AnimalsAndPeopleadjectives_Worksheet_EN.pdf",
              "ES": "WB_AnimalsAndPeopleadjectives_Worksheet_ES.pdf",
              "ZH": "WB_AnimalsAndPeopleadjectives_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_Animals and People (Adjectives).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-ZZA-Verbs1",
          "sortkey": {
            "EN": "2",
            "ES": "2",
            "ZH": "2"
          },
          "wblevel": {
            "EN": 10,
            "ES": 10,
            "ZH": 10
          },
          "content": "word-book",
          "title": {
            "EN": "I like",
            "ES": "Me gusta",
            "ZH": "喜欢"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The verb \"I like\" and what students \"like\" to do.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "\"I like\", verbs"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: park, bike, rainbow, sandwich, food, hammer, friends, tv, tiger, fox; Adjectives: good; Verbs: to like, to go, to ride, to look, to make, to taste, to use, to talk, to watch, to walk, to hide"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_ILike_Worksheet_EN.pdf",
              "ES": "WB_ILike_Worksheet_ES.pdf",
              "ZH": "WB_ILike_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_I like (Verbs).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-ZZB-Verbs2",
          "content": "word-book",
          "sortkey": {
            "EN": "3",
            "ES": "3",
            "ZH": "3"
          },
          "wblevel": {
            "EN": 10,
            "ES": 10,
            "ZH": 10
          },
          "title": {
            "EN": "I love",
            "ES": "Me encanta",
            "ZH": "爱, 非常喜欢"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The verb \"I love\" and what students \"love\" to do.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "\"I love\", verbs"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: girl, home, family, video games, friends, school, books, things, gifts, places; Adjectives: different; Verbs: to be, to live, to draw, to play, to sing, to learn, to read, to buy, to get, to travel"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_ILove_Worksheet_EN.pdf",
              "ES": "WB_ILove_Worksheet_ES.pdf",
              "ZH": "WB_ILove_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_I love (Verbs).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-ZZC-Verbs3",
          "content": "word-book",
          "sortkey": {
            "EN": "1",
            "ES": "1",
            "ZH": "1"
          },
          "wblevel": {
            "EN": 10,
            "ES": 10,
            "ZH": 10
          },
          "title": {
            "EN": "I can",
            "ES": "Puedo",
            "ZH": "可以，会"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The verb \"I can\" and what animals \"can\" do.",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "Animals, \"I can\", verbs"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: paper, ladder, music, leaves, fish, ocean, camel, water, deer, bird, shrimp, snake, animals; Adjectives: high, deep; Verbs: to be able, to cut, to climb, to listen, to jump, to swim, to drink, to run, to fly, to dig, to bite"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_ICan_Worksheet_EN.pdf",
              "ES": "WB_ICan_Worksheet_ES.pdf",
              "ZH": "WB_ICan_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_I can (Verbs).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "WB-ZZD-Verbs4",
          "content": "word-book",
          "sortkey": {
            "EN": "4",
            "ES": "4",
            "ZH": "4"
          },
          "wblevel": {
            "EN": 10,
            "ES": 10,
            "ZH": 10
          },
          "title": {
            "EN": "I want",
            "ES": "Quiero",
            "ZH": "想要"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-ZH"
          ],
          "about": "The verb \"I want\" and what students \"want \"or \"don't want.\"",
          "genre": [
            "vocabulary"
          ],
          "subjects": [
            "\"I want\", \"I don't want\", verbs"
          ],
          "grammar": [
            "Present"
          ],
          "keywords": [
            "Nouns: candy, dinner, friend, shoes, socks, pencil; Verbs: to want, to have, to eat, to come, to play, to sleep, to wear, to need, to write, to work, to find, to sit"
          ],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "WB_IWant_Worksheet_EN.pdf",
              "ES": "WB_IWant_Worksheet_ES.pdf",
              "ZH": "WB_IWant_Worksheet_ZH.pdf"
            },
            "lesson_plan": {
              "EN": "Lesson_Plan_I want (Verbs).pdf"
            }
          }
        },
        {
          "set": "WB",
          "path": "LB_DDX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "D d",
            "ES": "D d",
            "ZH": "D d",
            "VI": "D d",
            "AR": "D d",
            "KO": "D d",
            "DR": "D d",
            "PS": "D d",
            "TL": "D d",
            "BN": "D d",
            "PT": "D d",
            "HK": "D d",
            "TW": "D d"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"D\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "dance, deer, dish, doll, duck, door",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_DDX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OYX",
          "content": "letter-book",
          "wblevel": {
            "EN": 8,
            "ES": 8,
            "ZH": 8,
            "VI": 8,
            "AR": 8,
            "KO": 8,
            "DR": 8,
            "PS": 8,
            "TL": 8,
            "BN": 8,
            "PT": 8,
            "HK": 8,
            "TW": 8
          },
          "title": {
            "EN": "OY  oy",
            "ES": "OY  oy",
            "ZH": "OY  oy",
            "VI": "OY  oy",
            "AR": "OY  oy",
            "KO": "OY  oy",
            "DR": "OY  oy",
            "PS": "OY  oy",
            "TL": "OY  oy",
            "BN": "OY  oy",
            "PT": "OY  oy",
            "HK": "OY  oy",
            "TW": "OY  oy"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"OY\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "toy, boy, joy, cowboy, destroy, enjoy",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OYX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_EAS",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "EA  ea (short \"e\"sound)",
            "ES": "EA  ea (short \"e\"sound)",
            "ZH": "EA  ea (short \"e\"sound)",
            "VI": "EA  ea (short \"e\"sound)",
            "AR": "EA  ea (short \"e\"sound)",
            "KO": "EA  ea (short \"e\"sound)",
            "DR": "EA  ea (short \"e\"sound)",
            "PS": "EA  ea (short \"e\"sound)",
            "TL": "EA  ea (short \"e\"sound)",
            "BN": "EA  ea (short \"e\"sound)",
            "PT": "EA  ea (short \"e\"sound)",
            "HK": "EA  ea (short \"e\"sound)",
            "TW": "EA  ea (short \"e\"sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"EA\" digraph, sounding like  short \"E\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "bread, thread, heavy, weather, head, sweater",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_EAS_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_AAL",
          "content": "letter-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "title": {
            "EN": "A a  (long a)",
            "ES": "A a  (long a)",
            "ZH": "A a  (long a)",
            "VI": "A a  (long a)",
            "AR": "A a  (long a)",
            "KO": "A a  (long a)",
            "DR": "A a  (long a)",
            "PS": "A a  (long a)",
            "TL": "A a  (long a)",
            "BN": "A a  (long a)",
            "PT": "A a  (long a)",
            "HK": "A a  (long a)",
            "TW": "A a  (long a)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with long \"A\" vowel sounds",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "ace, bake, cake, lake, plane, snake",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_AAL_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_FFX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "F f",
            "ES": "F f",
            "ZH": "F f",
            "VI": "F f",
            "AR": "F f",
            "KO": "F f",
            "DR": "F f",
            "PS": "F f",
            "TL": "F f",
            "BN": "F f",
            "PT": "F f",
            "HK": "F f",
            "TW": "F f"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"F\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "fish, farm, family, frog, fire, fly",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_FFX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_IIL",
          "content": "letter-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "title": {
            "EN": "I i   (long i)",
            "ES": "I i   (long i)",
            "ZH": "I i   (long i)",
            "VI": "I i   (long i)",
            "AR": "I i   (long i)",
            "KO": "I i   (long i)",
            "DR": "I i   (long i)",
            "PS": "I i   (long i)",
            "TL": "I i   (long i)",
            "BN": "I i   (long i)",
            "PT": "I i   (long i)",
            "HK": "I i   (long i)",
            "TW": "I i   (long i)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with long \"I\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "ice, bike, ride, five, time, hide",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_IIL_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_XXX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "X x",
            "ES": "X x",
            "ZH": "X x",
            "VI": "X x",
            "AR": "X x",
            "KO": "X x",
            "DR": "X x",
            "PS": "X x",
            "TL": "X x",
            "BN": "X x",
            "PT": "X x",
            "HK": "X x",
            "TW": "X x"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"X\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "axe, box, fix, fox, taxi, wax",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_XXX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_WWX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "W w ",
            "ES": "W w ",
            "ZH": "W w ",
            "VI": "W w ",
            "AR": "W w ",
            "KO": "W w ",
            "DR": "W w ",
            "PS": "W w ",
            "TL": "W w ",
            "BN": "W w ",
            "PT": "W w ",
            "HK": "W w ",
            "TW": "W w "
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"W\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "worm, web, well, wet, wing, world",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_WWX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_QQX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "Q q",
            "ES": "Q q",
            "ZH": "Q q",
            "VI": "Q q",
            "AR": "Q q",
            "KO": "Q q",
            "DR": "Q q",
            "PS": "Q q",
            "TL": "Q q",
            "BN": "Q q",
            "PT": "Q q",
            "HK": "Q q",
            "TW": "Q q"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"Q\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "quick, quiz, quarter, quote, question",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_QQX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_UUS",
          "content": "letter-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "title": {
            "EN": "U u  (short u)",
            "ES": "U u  (short u)",
            "ZH": "U u  (short u)",
            "VI": "U u  (short u)",
            "AR": "U u  (short u)",
            "KO": "U u  (short u)",
            "DR": "U u  (short u)",
            "PS": "U u  (short u)",
            "TL": "U u  (short u)",
            "BN": "U u  (short u)",
            "PT": "U u  (short u)",
            "HK": "U u  (short u)",
            "TW": "U u  (short u)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with long \"U\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "up, bug, bus, cup, sun, hug",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_UUS_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_IIS",
          "content": "letter-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "title": {
            "EN": "I i   (short i)",
            "ES": "I i   (short i)",
            "ZH": "I i   (short i)",
            "VI": "I i   (short i)",
            "AR": "I i   (short i)",
            "KO": "I i   (short i)",
            "DR": "I i   (short i)",
            "PS": "I i   (short i)",
            "TL": "I i   (short i)",
            "BN": "I i   (short i)",
            "PT": "I i   (short i)",
            "HK": "I i   (short i)",
            "TW": "I i   (short i)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with short \"I\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "in, dig, mix, lip, win, sit",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_IIS_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_YIX",
          "content": "letter-book",
          "wblevel": {
            "EN": 7,
            "ES": 7,
            "ZH": 7,
            "VI": 7,
            "AR": 7,
            "KO": 7,
            "DR": 7,
            "PS": 7,
            "TL": 7,
            "BN": 7,
            "PT": 7,
            "HK": 7,
            "TW": 7
          },
          "title": {
            "EN": "...Y  y  (long \"i\" sound)",
            "ES": "...Y  y  (long \"i\" sound)",
            "ZH": "...Y  y  (long \"i\" sound)",
            "VI": "...Y  y  (long \"i\" sound)",
            "AR": "...Y  y  (long \"i\" sound)",
            "KO": "...Y  y  (long \"i\" sound)",
            "DR": "...Y  y  (long \"i\" sound)",
            "PS": "...Y  y  (long \"i\" sound)",
            "TL": "...Y  y  (long \"i\" sound)",
            "BN": "...Y  y  (long \"i\" sound)",
            "PT": "...Y  y  (long \"i\" sound)",
            "HK": "...Y  y  (long \"i\" sound)",
            "TW": "...Y  y  (long \"i\" sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words ending with \"Y\", sounding like \"I\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "cry, dry, fry, shy, sky, fly",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_YIX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_CCX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "C c",
            "ES": "C c",
            "ZH": "C c",
            "VI": "C c",
            "AR": "C c",
            "KO": "C c",
            "DR": "C c",
            "PS": "C c",
            "TL": "C c",
            "BN": "C c",
            "PT": "C c",
            "HK": "C c",
            "TW": "C c"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"C\", sounding like \"K\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "cat, car, cow, corn, cage, candy",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_CCX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_IEX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "IE  ie",
            "ES": "IE  ie",
            "ZH": "IE  ie",
            "VI": "IE  ie",
            "AR": "IE  ie",
            "KO": "IE  ie",
            "DR": "IE  ie",
            "PS": "IE  ie",
            "TL": "IE  ie",
            "BN": "IE  ie",
            "PT": "IE  ie",
            "HK": "IE  ie",
            "TW": "IE  ie"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"IE\" digraph, sounding like long \"E\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "chief, piece, field, shield, thief, movie",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_IEX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_GGX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "G g",
            "ES": "G g",
            "ZH": "G g",
            "VI": "G g",
            "AR": "G g",
            "KO": "G g",
            "DR": "G g",
            "PS": "G g",
            "TL": "G g",
            "BN": "G g",
            "PT": "G g",
            "HK": "G g",
            "TW": "G g"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"G\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "girl, game, grape, grass, glove, gold",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_GGX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_PPX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "P p",
            "ES": "P p",
            "ZH": "P p",
            "VI": "P p",
            "AR": "P p",
            "KO": "P p",
            "DR": "P p",
            "PS": "P p",
            "TL": "P p",
            "BN": "P p",
            "PT": "P p",
            "HK": "P p",
            "TW": "P p"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"P\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "pan, pet, pig, park, paper, pen",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_PPX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OLO",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "OO  oo  (long \"oo\" sound)",
            "ES": "OO  oo  (long \"oo\" sound)",
            "ZH": "OO  oo  (long \"oo\" sound)",
            "VI": "OO  oo  (long \"oo\" sound)",
            "AR": "OO  oo  (long \"oo\" sound)",
            "KO": "OO  oo  (long \"oo\" sound)",
            "DR": "OO  oo  (long \"oo\" sound)",
            "PS": "OO  oo  (long \"oo\" sound)",
            "TL": "OO  oo  (long \"oo\" sound)",
            "BN": "OO  oo  (long \"oo\" sound)",
            "PT": "OO  oo  (long \"oo\" sound)",
            "HK": "OO  oo  (long \"oo\" sound)",
            "TW": "OO  oo  (long \"oo\" sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"OO\" digraph, sounding like long \"U\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "food, root, pool, spoon, roof, tools",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OLO_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_WHX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "WH  wh",
            "ES": "WH  wh",
            "ZH": "WH  wh",
            "VI": "WH  wh",
            "AR": "WH  wh",
            "KO": "WH  wh",
            "DR": "WH  wh",
            "PS": "WH  wh",
            "TL": "WH  wh",
            "BN": "WH  wh",
            "PT": "WH  wh",
            "HK": "WH  wh",
            "TW": "WH  wh"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"WH\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "whale, wheel, white, wheat, whistle, why",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_WHX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OWX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "OW  ow",
            "ES": "OW  ow",
            "ZH": "OW  ow",
            "VI": "OW  ow",
            "AR": "OW  ow",
            "KO": "OW  ow",
            "DR": "OW  ow",
            "PS": "OW  ow",
            "TL": "OW  ow",
            "BN": "OW  ow",
            "PT": "OW  ow",
            "HK": "OW  ow",
            "TW": "OW  ow"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"OW\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "owl, clown, towel, crown, frown, town",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OWX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_MMX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "M m",
            "ES": "M m",
            "ZH": "M m",
            "VI": "M m",
            "AR": "M m",
            "KO": "M m",
            "DR": "M m",
            "PS": "M m",
            "TL": "M m",
            "BN": "M m",
            "PT": "M m",
            "HK": "M m",
            "TW": "M m"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"M\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "map, meat, moon, meal, music, money",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_MMX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OUX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "OU  ou",
            "ES": "OU  ou",
            "ZH": "OU  ou",
            "VI": "OU  ou",
            "AR": "OU  ou",
            "KO": "OU  ou",
            "DR": "OU  ou",
            "PS": "OU  ou",
            "TL": "OU  ou",
            "BN": "OU  ou",
            "PT": "OU  ou",
            "HK": "OU  ou",
            "TW": "OU  ou"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"OU\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "cloud, house, loud, mouse, sour, round",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OUX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_AIX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "AI  ai ",
            "ES": "AI  ai ",
            "ZH": "AI  ai ",
            "VI": "AI  ai ",
            "AR": "AI  ai ",
            "KO": "AI  ai ",
            "DR": "AI  ai ",
            "PS": "AI  ai ",
            "TL": "AI  ai ",
            "BN": "AI  ai ",
            "PT": "AI  ai ",
            "HK": "AI  ai ",
            "TW": "AI  ai "
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"AI\" digraph, sounding like long \"A\" ",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "paint, waist, rain, snail, tail, train",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_AIX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_CHX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "CH  ch",
            "ES": "CH  ch",
            "ZH": "CH  ch",
            "VI": "CH  ch",
            "AR": "CH  ch",
            "KO": "CH  ch",
            "DR": "CH  ch",
            "PS": "CH  ch",
            "TL": "CH  ch",
            "BN": "CH  ch",
            "PT": "CH  ch",
            "HK": "CH  ch",
            "TW": "CH  ch"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"CH\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "chair, cheese, chips, chin, cherry, chicken",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_CHX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_PHX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "ph",
            "ES": "ph",
            "ZH": "ph",
            "VI": "ph",
            "AR": "ph",
            "KO": "ph",
            "DR": "ph",
            "PS": "ph",
            "TL": "ph",
            "BN": "ph",
            "PT": "ph",
            "HK": "ph",
            "TW": "ph"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"PH\" digraph, sounding like \"F\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "phone, graph, photo, paragraph, dolphin, trophy",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_PHX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_SHX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "SH  sh",
            "ES": "SH  sh",
            "ZH": "SH  sh",
            "VI": "SH  sh",
            "AR": "SH  sh",
            "KO": "SH  sh",
            "DR": "SH  sh",
            "PS": "SH  sh",
            "TL": "SH  sh",
            "BN": "SH  sh",
            "PT": "SH  sh",
            "HK": "SH  sh",
            "TW": "SH  sh"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"SH\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "sheep, shell, shark, ship, shop, shirt",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_SHX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_VVX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "V v",
            "ES": "V v",
            "ZH": "V v",
            "VI": "V v",
            "AR": "V v",
            "KO": "V v",
            "DR": "V v",
            "PS": "V v",
            "TL": "V v",
            "BN": "V v",
            "PT": "V v",
            "HK": "V v",
            "TW": "V v"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"V\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "van, vase, vine, vote, volcano, vegetables",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_VVX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_BBX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "B b",
            "ES": "B b",
            "ZH": "B b",
            "VI": "B b",
            "AR": "B b",
            "KO": "B b",
            "DR": "B b",
            "PS": "B b",
            "TL": "B b",
            "BN": "B b",
            "PT": "B b",
            "HK": "B b",
            "TW": "B b"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"B\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "ball, bell, baby, bird, body, bed",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_BBX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_YYX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "Y y",
            "ES": "Y y",
            "ZH": "Y y",
            "VI": "Y y",
            "AR": "Y y",
            "KO": "Y y",
            "DR": "Y y",
            "PS": "Y y",
            "TL": "Y y",
            "BN": "Y y",
            "PT": "Y y",
            "HK": "Y y",
            "TW": "Y y"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"Y\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "yell, yawn, yogurt, year, yoyo, yellow",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_YYX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OOS",
          "content": "letter-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "title": {
            "EN": "O o  (short o)",
            "ES": "O o  (short o)",
            "ZH": "O o  (short o)",
            "VI": "O o  (short o)",
            "AR": "O o  (short o)",
            "KO": "O o  (short o)",
            "DR": "O o  (short o)",
            "PS": "O o  (short o)",
            "TL": "O o  (short o)",
            "BN": "O o  (short o)",
            "PT": "O o  (short o)",
            "HK": "O o  (short o)",
            "TW": "O o  (short o)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with short \"O\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "on, hop, log, mop, top, pot",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OOS_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_EAL",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "EA  ea (long \"e\" sound)",
            "ES": "EA  ea (long \"e\" sound)",
            "ZH": "EA  ea (long \"e\" sound)",
            "VI": "EA  ea (long \"e\" sound)",
            "AR": "EA  ea (long \"e\" sound)",
            "KO": "EA  ea (long \"e\" sound)",
            "DR": "EA  ea (long \"e\" sound)",
            "PS": "EA  ea (long \"e\" sound)",
            "TL": "EA  ea (long \"e\" sound)",
            "BN": "EA  ea (long \"e\" sound)",
            "PT": "EA  ea (long \"e\" sound)",
            "HK": "EA  ea (long \"e\" sound)",
            "TW": "EA  ea (long \"e\" sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"EA\" digraph, sounding like  long \"E\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "ear, heat, bean, peas, peach, team",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_EAL_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_NNX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "N n",
            "ES": "N n",
            "ZH": "N n",
            "VI": "N n",
            "AR": "N n",
            "KO": "N n",
            "DR": "N n",
            "PS": "N n",
            "TL": "N n",
            "BN": "N n",
            "PT": "N n",
            "HK": "N n",
            "TW": "N n"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"N\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "nap, neck, nose, nut, nose, net",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_NNX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_UEX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "UE  ue",
            "ES": "UE  ue",
            "ZH": "UE  ue",
            "VI": "UE  ue",
            "AR": "UE  ue",
            "KO": "UE  ue",
            "DR": "UE  ue",
            "PS": "UE  ue",
            "TL": "UE  ue",
            "BN": "UE  ue",
            "PT": "UE  ue",
            "HK": "UE  ue",
            "TW": "UE  ue"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"UE\" digraph, sounding like long \"U\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "glue, clue, true, blue, rescue, argue",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_UEX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_THX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "TH  th",
            "ES": "TH  th",
            "ZH": "TH  th",
            "VI": "TH  th",
            "AR": "TH  th",
            "KO": "TH  th",
            "DR": "TH  th",
            "PS": "TH  th",
            "TL": "TH  th",
            "BN": "TH  th",
            "PT": "TH  th",
            "HK": "TH  th",
            "TW": "TH  th"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"TH\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "thin, throne, three, thumb, thorn, throat",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_THX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_ZZX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "Z z",
            "ES": "Z z",
            "ZH": "Z z",
            "VI": "Z z",
            "AR": "Z z",
            "KO": "Z z",
            "DR": "Z z",
            "PS": "Z z",
            "TL": "Z z",
            "BN": "Z z",
            "PT": "Z z",
            "HK": "Z z",
            "TW": "Z z"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"Z\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "zero, zoo, zipper, zombie, zoom, zigzag",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_ZZX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_HHX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "H h",
            "ES": "H h",
            "ZH": "H h",
            "VI": "H h",
            "AR": "H h",
            "KO": "H h",
            "DR": "H h",
            "PS": "H h",
            "TL": "H h",
            "BN": "H h",
            "PT": "H h",
            "HK": "H h",
            "TW": "H h"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"H\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "hat, hair, hand, horse, honey, home",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_HHX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_NGX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "NG  ng",
            "ES": "NG  ng",
            "ZH": "NG  ng",
            "VI": "NG  ng",
            "AR": "NG  ng",
            "KO": "NG  ng",
            "DR": "NG  ng",
            "PS": "NG  ng",
            "TL": "NG  ng",
            "BN": "NG  ng",
            "PT": "NG  ng",
            "HK": "NG  ng",
            "TW": "NG  ng"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"NG\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "sing, swing, hang, string, strong, spring",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_NGX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_AAS",
          "content": "letter-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "title": {
            "EN": "A a  (short a)",
            "ES": "A a  (short a)",
            "ZH": "A a  (short a)",
            "VI": "A a  (short a)",
            "AR": "A a  (short a)",
            "KO": "A a  (short a)",
            "DR": "A a  (short a)",
            "PS": "A a  (short a)",
            "TL": "A a  (short a)",
            "BN": "A a  (short a)",
            "PT": "A a  (short a)",
            "HK": "A a  (short a)",
            "TW": "A a  (short a)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words  starting with short \"A\" vowel sounds",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "ant, bat, crab, fan, ham, pants",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_AAS_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_EIX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "EI  ei ",
            "ES": "EI  ei ",
            "ZH": "EI  ei ",
            "VI": "EI  ei ",
            "AR": "EI  ei ",
            "KO": "EI  ei ",
            "DR": "EI  ei ",
            "PS": "EI  ei ",
            "TL": "EI  ei ",
            "BN": "EI  ei ",
            "PT": "EI  ei ",
            "HK": "EI  ei ",
            "TW": "EI  ei "
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"EI\" digraph, sounding like long \"A\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "weigh, sleigh, eight, reindeer, neighbor, freight",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_EIX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_SSX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "S s",
            "ES": "S s",
            "ZH": "S s",
            "VI": "S s",
            "AR": "S s",
            "KO": "S s",
            "DR": "S s",
            "PS": "S s",
            "TL": "S s",
            "BN": "S s",
            "PT": "S s",
            "HK": "S s",
            "TW": "S s"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"S\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "stop, smile, stick, soup, star, sock",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_SSX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_YEX",
          "content": "letter-book",
          "wblevel": {
            "EN": 7,
            "ES": 7,
            "ZH": 7,
            "VI": 7,
            "AR": 7,
            "KO": 7,
            "DR": 7,
            "PS": 7,
            "TL": 7,
            "BN": 7,
            "PT": 7,
            "HK": 7,
            "TW": 7
          },
          "title": {
            "EN": "…Y  y  (long \"e\" sound)",
            "ES": "…Y  y  (long \"e\" sound)",
            "ZH": "…Y  y  (long \"e\" sound)",
            "VI": "…Y  y  (long \"e\" sound)",
            "AR": "…Y  y  (long \"e\" sound)",
            "KO": "…Y  y  (long \"e\" sound)",
            "DR": "…Y  y  (long \"e\" sound)",
            "PS": "…Y  y  (long \"e\" sound)",
            "TL": "…Y  y  (long \"e\" sound)",
            "BN": "…Y  y  (long \"e\" sound)",
            "PT": "…Y  y  (long \"e\" sound)",
            "HK": "…Y  y  (long \"e\" sound)",
            "TW": "…Y  y  (long \"e\" sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words ending with \"Y\", sounding like \"E\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "bunny, funny, happy, puppy, windy, sunny",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_YEX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_EYX",
          "content": "letter-book",
          "wblevel": {
            "EN": 8,
            "ES": 8,
            "ZH": 8,
            "VI": 8,
            "AR": 8,
            "KO": 8,
            "DR": 8,
            "PS": 8,
            "TL": 8,
            "BN": 8,
            "PT": 8,
            "HK": 8,
            "TW": 8
          },
          "title": {
            "EN": "EY  ey ",
            "ES": "EY  ey ",
            "ZH": "EY  ey ",
            "VI": "EY  ey ",
            "AR": "EY  ey ",
            "KO": "EY  ey ",
            "DR": "EY  ey ",
            "PS": "EY  ey ",
            "TL": "EY  ey ",
            "BN": "EY  ey ",
            "PT": "EY  ey ",
            "HK": "EY  ey ",
            "TW": "EY  ey "
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"EY\" digraph, sounding like long \"E\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "donkey, monkey, chimney, valley, trolley, hockey",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_EYX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_EES",
          "content": "letter-book",
          "wblevel": {
            "EN": 3,
            "ES": 3,
            "ZH": 3,
            "VI": 3,
            "AR": 3,
            "KO": 3,
            "DR": 3,
            "PS": 3,
            "TL": 3,
            "BN": 3,
            "PT": 3,
            "HK": 3,
            "TW": 3
          },
          "title": {
            "EN": "E e  (short e)",
            "ES": "E e  (short e)",
            "ZH": "E e  (short e)",
            "VI": "E e  (short e)",
            "AR": "E e  (short e)",
            "KO": "E e  (short e)",
            "DR": "E e  (short e)",
            "PS": "E e  (short e)",
            "TL": "E e  (short e)",
            "BN": "E e  (short e)",
            "PT": "E e  (short e)",
            "HK": "E e  (short e)",
            "TW": "E e  (short e)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with long \"E\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "egg, hen, red, smell, nest, tent",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_EES_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OWL",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "OW  ow (long \"o\" sound)",
            "ES": "OW  ow (long \"o\" sound)",
            "ZH": "OW  ow (long \"o\" sound)",
            "VI": "OW  ow (long \"o\" sound)",
            "AR": "OW  ow (long \"o\" sound)",
            "KO": "OW  ow (long \"o\" sound)",
            "DR": "OW  ow (long \"o\" sound)",
            "PS": "OW  ow (long \"o\" sound)",
            "TL": "OW  ow (long \"o\" sound)",
            "BN": "OW  ow (long \"o\" sound)",
            "PT": "OW  ow (long \"o\" sound)",
            "HK": "OW  ow (long \"o\" sound)",
            "TW": "OW  ow (long \"o\" sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"OW\" digraph, sounding like long \"O\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "blow, grow, snow, bowl, arrow, throw",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OWL_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_CKX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "CK  ck",
            "ES": "CK  ck",
            "ZH": "CK  ck",
            "VI": "CK  ck",
            "AR": "CK  ck",
            "KO": "CK  ck",
            "DR": "CK  ck",
            "PS": "CK  ck",
            "TL": "CK  ck",
            "BN": "CK  ck",
            "PT": "CK  ck",
            "HK": "CK  ck",
            "TW": "CK  ck"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"CK\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "back, backpack, sick, lock, truck, luck",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_CKX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_RRX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "R r",
            "ES": "R r",
            "ZH": "R r",
            "VI": "R r",
            "AR": "R r",
            "KO": "R r",
            "DR": "R r",
            "PS": "R r",
            "TL": "R r",
            "BN": "R r",
            "PT": "R r",
            "HK": "R r",
            "TW": "R r"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"R\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "rat, ring, rock, rice, rug, run",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_RRX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_EEL",
          "content": "letter-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "title": {
            "EN": "E e  (long e)",
            "ES": "E e  (long e)",
            "ZH": "E e  (long e)",
            "VI": "E e  (long e)",
            "AR": "E e  (long e)",
            "KO": "E e  (long e)",
            "DR": "E e  (long e)",
            "PS": "E e  (long e)",
            "TL": "E e  (long e)",
            "BN": "E e  (long e)",
            "PT": "E e  (long e)",
            "HK": "E e  (long e)",
            "TW": "E e  (long e)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with short \"E\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "email, equal, eve, here, scene, these",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_EEL_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_WRX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "WR  wr",
            "ES": "WR  wr",
            "ZH": "WR  wr",
            "VI": "WR  wr",
            "AR": "WR  wr",
            "KO": "WR  wr",
            "DR": "WR  wr",
            "PS": "WR  wr",
            "TL": "WR  wr",
            "BN": "WR  wr",
            "PT": "WR  wr",
            "HK": "WR  wr",
            "TW": "WR  wr"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"WR\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "wrist, wreck, wrong, write, wrench, wreath",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_WRX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_CCS",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "C c  (\"s\" sound)",
            "ES": "C c  (\"s\" sound)",
            "ZH": "C c  (\"s\" sound)",
            "VI": "C c  (\"s\" sound)",
            "AR": "C c  (\"s\" sound)",
            "KO": "C c  (\"s\" sound)",
            "DR": "C c  (\"s\" sound)",
            "PS": "C c  (\"s\" sound)",
            "TL": "C c  (\"s\" sound)",
            "BN": "C c  (\"s\" sound)",
            "PT": "C c  (\"s\" sound)",
            "HK": "C c  (\"s\" sound)",
            "TW": "C c  (\"s\" sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"C\", sounding like \"S\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "city, cents, circus, celery, cereal, circle",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_CCS_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_AYX",
          "content": "letter-book",
          "wblevel": {
            "EN": 8,
            "ES": 8,
            "ZH": 8,
            "VI": 8,
            "AR": 8,
            "KO": 8,
            "DR": 8,
            "PS": 8,
            "TL": 8,
            "BN": 8,
            "PT": 8,
            "HK": 8,
            "TW": 8
          },
          "title": {
            "EN": "AY  ay ",
            "ES": "AY  ay ",
            "ZH": "AY  ay ",
            "VI": "AY  ay ",
            "AR": "AY  ay ",
            "KO": "AY  ay ",
            "DR": "AY  ay ",
            "PS": "AY  ay ",
            "TL": "AY  ay ",
            "BN": "AY  ay ",
            "PT": "AY  ay ",
            "HK": "AY  ay ",
            "TW": "AY  ay "
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"AY\" digraph, sounding like long \"A\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "hay, day, play, tray, pay, spray",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_AYX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_KNX",
          "content": "letter-book",
          "wblevel": {
            "EN": 5,
            "ES": 5,
            "ZH": 5,
            "VI": 5,
            "AR": 5,
            "KO": 5,
            "DR": 5,
            "PS": 5,
            "TL": 5,
            "BN": 5,
            "PT": 5,
            "HK": 5,
            "TW": 5
          },
          "title": {
            "EN": "KN  kn",
            "ES": "KN  kn",
            "ZH": "KN  kn",
            "VI": "KN  kn",
            "AR": "KN  kn",
            "KO": "KN  kn",
            "DR": "KN  kn",
            "PS": "KN  kn",
            "TL": "KN  kn",
            "BN": "KN  kn",
            "PT": "KN  kn",
            "HK": "KN  kn",
            "TW": "KN  kn"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"KN\" digraph, sounding like \"N\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "knee, knit, knot, knife, knock, knight",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_KNX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_TTX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "T t",
            "ES": "T t",
            "ZH": "T t",
            "VI": "T t",
            "AR": "T t",
            "KO": "T t",
            "DR": "T t",
            "PS": "T t",
            "TL": "T t",
            "BN": "T t",
            "PT": "T t",
            "HK": "T t",
            "TW": "T t"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"T\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "table, test, toys, tomato, turtle, tiger",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_TTX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_KKX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "K k",
            "ES": "K k",
            "ZH": "K k",
            "VI": "K k",
            "AR": "K k",
            "KO": "K k",
            "DR": "K k",
            "PS": "K k",
            "TL": "K k",
            "BN": "K k",
            "PT": "K k",
            "HK": "K k",
            "TW": "K k"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"K\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "king, kick, kite, key, kiss, kitchen",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_KKX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_EEX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "EE  ee",
            "ES": "EE  ee",
            "ZH": "EE  ee",
            "VI": "EE  ee",
            "AR": "EE  ee",
            "KO": "EE  ee",
            "DR": "EE  ee",
            "PS": "EE  ee",
            "TL": "EE  ee",
            "BN": "EE  ee",
            "PT": "EE  ee",
            "HK": "EE  ee",
            "TW": "EE  ee"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"EE\"digraph,  sounding like long \"E\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "bee, tree, seed, feet, sleep, teeth",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_EEX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_AWX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "a w",
            "ES": "a w",
            "ZH": "a w",
            "VI": "a w",
            "AR": "a w",
            "KO": "a w",
            "DR": "a w",
            "PS": "a w",
            "TL": "a w",
            "BN": "a w",
            "PT": "a w",
            "HK": "a w",
            "TW": "a w"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"AW\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "claw, hawk, crawl, jaw, paw, yawn",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_AWX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OSO",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "OO  oo  (short \"oo\" sound)",
            "ES": "OO  oo  (short \"oo\" sound)",
            "ZH": "OO  oo  (short \"oo\" sound)",
            "VI": "OO  oo  (short \"oo\" sound)",
            "AR": "OO  oo  (short \"oo\" sound)",
            "KO": "OO  oo  (short \"oo\" sound)",
            "DR": "OO  oo  (short \"oo\" sound)",
            "PS": "OO  oo  (short \"oo\" sound)",
            "TL": "OO  oo  (short \"oo\" sound)",
            "BN": "OO  oo  (short \"oo\" sound)",
            "PT": "OO  oo  (short \"oo\" sound)",
            "HK": "OO  oo  (short \"oo\" sound)",
            "TW": "OO  oo  (short \"oo\" sound)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"OO\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "look, book, cook, foot, wood, hook",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OSO_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OIX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "OI  oi",
            "ES": "OI  oi",
            "ZH": "OI  oi",
            "VI": "OI  oi",
            "AR": "OI  oi",
            "KO": "OI  oi",
            "DR": "OI  oi",
            "PS": "OI  oi",
            "TL": "OI  oi",
            "BN": "OI  oi",
            "PT": "OI  oi",
            "HK": "OI  oi",
            "TW": "OI  oi"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"OI\" digraph",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "oil, boil, coins, point, toilet, soil",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OIX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_UUL",
          "content": "letter-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "title": {
            "EN": "U u  (long u)",
            "ES": "U u  (long u)",
            "ZH": "U u  (long u)",
            "VI": "U u  (long u)",
            "AR": "U u  (long u)",
            "KO": "U u  (long u)",
            "DR": "U u  (long u)",
            "PS": "U u  (long u)",
            "TL": "U u  (long u)",
            "BN": "U u  (long u)",
            "PT": "U u  (long u)",
            "HK": "U u  (long u)",
            "TW": "U u  (long u)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with short \"U\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "use, flute, huge, ruler, tune, tube",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_UUL_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_AUX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "AU au",
            "ES": "AU au",
            "ZH": "AU au",
            "VI": "AU au",
            "AR": "AU au",
            "KO": "AU au",
            "DR": "AU au",
            "PS": "AU au",
            "TL": "AU au",
            "BN": "AU au",
            "PT": "AU au",
            "HK": "AU au",
            "TW": "AU au"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"AU\" digraph, sounding like short \"O\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "caught, naughty, daughter, faucet, autumn, taught",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_AUX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_ALP",
          "content": "letter-book",
          "wblevel": {
            "EN": 1,
            "ES": 1,
            "ZH": 1,
            "VI": 1,
            "AR": 1,
            "KO": 1,
            "DR": 1,
            "PS": 1,
            "TL": 1,
            "BN": 1,
            "PT": 1,
            "HK": 1,
            "TW": 1
          },
          "title": {
            "EN": "Alphabet Book",
            "ES": "Alphabet Book",
            "ZH": "Alphabet Book",
            "VI": "Alphabet Book",
            "AR": "Alphabet Book",
            "KO": "Alphabet Book",
            "DR": "Alphabet Book",
            "PS": "Alphabet Book",
            "TL": "Alphabet Book",
            "BN": "Alphabet Book",
            "PT": "Alphabet Book",
            "HK": "Alphabet Book",
            "TW": "Alphabet Book"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "",
          "genre": [],
          "subjects": [],
          "grammar": [],
          "keywords": [],
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": null
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_JJX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "J j",
            "ES": "J j",
            "ZH": "J j",
            "VI": "J j",
            "AR": "J j",
            "KO": "J j",
            "DR": "J j",
            "PS": "J j",
            "TL": "J j",
            "BN": "J j",
            "PT": "J j",
            "HK": "J j",
            "TW": "J j"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"J\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "jar, jet, jelly, job, jump, juice",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_JJX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OOL",
          "content": "letter-book",
          "wblevel": {
            "EN": 4,
            "ES": 4,
            "ZH": 4,
            "VI": 4,
            "AR": 4,
            "KO": 4,
            "DR": 4,
            "PS": 4,
            "TL": 4,
            "BN": 4,
            "PT": 4,
            "HK": 4,
            "TW": 4
          },
          "title": {
            "EN": "O o  (long o)",
            "ES": "O o  (long o)",
            "ZH": "O o  (long o)",
            "VI": "O o  (long o)",
            "AR": "O o  (long o)",
            "KO": "O o  (long o)",
            "DR": "O o  (long o)",
            "PS": "O o  (long o)",
            "TL": "O o  (long o)",
            "BN": "O o  (long o)",
            "PT": "O o  (long o)",
            "HK": "O o  (long o)",
            "TW": "O o  (long o)"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with long \"O\" vowel sound",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "open, hole, note, pole, smoke, rope",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OOL_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_OAX",
          "content": "letter-book",
          "wblevel": {
            "EN": 6,
            "ES": 6,
            "ZH": 6,
            "VI": 6,
            "AR": 6,
            "KO": 6,
            "DR": 6,
            "PS": 6,
            "TL": 6,
            "BN": 6,
            "PT": 6,
            "HK": 6,
            "TW": 6
          },
          "title": {
            "EN": "OA  oa",
            "ES": "OA  oa",
            "ZH": "OA  oa",
            "VI": "OA  oa",
            "AR": "OA  oa",
            "KO": "OA  oa",
            "DR": "OA  oa",
            "PS": "OA  oa",
            "TL": "OA  oa",
            "BN": "OA  oa",
            "PT": "OA  oa",
            "HK": "OA  oa",
            "TW": "OA  oa"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words wit \"OA\" digraph, sounding like long \"O\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "boat, coat, road, soap, toast, goal",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_OAX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_LLX",
          "content": "letter-book",
          "wblevel": {
            "EN": 2,
            "ES": 2,
            "ZH": 2,
            "VI": 2,
            "AR": 2,
            "KO": 2,
            "DR": 2,
            "PS": 2,
            "TL": 2,
            "BN": 2,
            "PT": 2,
            "HK": 2,
            "TW": 2
          },
          "title": {
            "EN": "L l",
            "ES": "L l",
            "ZH": "L l",
            "VI": "L l",
            "AR": "L l",
            "KO": "L l",
            "DR": "L l",
            "PS": "L l",
            "TL": "L l",
            "BN": "L l",
            "PT": "L l",
            "HK": "L l",
            "TW": "L l"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words starting with \"L\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "leaf, lizard, lamp, ladder, leg, lunch",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_LLX_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        },
        {
          "set": "WB",
          "path": "LB_IGH",
          "content": "letter-book",
          "wblevel": {
            "EN": 9,
            "ES": 9,
            "ZH": 9,
            "VI": 9,
            "AR": 9,
            "KO": 9,
            "DR": 9,
            "PS": 9,
            "TL": 9,
            "BN": 9,
            "PT": 9,
            "HK": 9,
            "TW": 9
          },
          "title": {
            "EN": "igh",
            "ES": "igh",
            "ZH": "igh",
            "VI": "igh",
            "AR": "igh",
            "KO": "igh",
            "DR": "igh",
            "PS": "igh",
            "TL": "igh",
            "BN": "igh",
            "PT": "igh",
            "HK": "igh",
            "TW": "igh"
          },
          "level": {
            "EN": 0,
            "ES": 0,
            "ZH": 0,
            "VI": 0,
            "AR": 0,
            "KO": 0,
            "DR": 0,
            "PS": 0,
            "TL": 0,
            "BN": 0,
            "PT": 0,
            "HK": 0,
            "TW": 0
          },
          "lexile": {
            "EN": -1,
            "ES": -1,
            "ZH": -1,
            "VI": -1,
            "AR": -1,
            "KO": -1,
            "DR": -1,
            "PS": -1,
            "TL": -1,
            "BN": -1,
            "PT": -1,
            "HK": -1,
            "TW": -1
          },
          "fp": "A",
          "versions": [
            "EN-ES",
            "EN-AR",
            "EN-BN",
            "EN-KO",
            "EN-PT",
            "EN-TL",
            "EN-VI",
            "EN-ZH"
          ],
          "about": "Words with \"IGH\" trigraph, sounding like \"I\"",
          "genre": [
            "Phonics"
          ],
          "subjects": [
            "NA"
          ],
          "grammar": [
            "NA"
          ],
          "keywords": "fight, light, right, sight, thigh, night",
          "mine": true,
          "display": "normal",
          "materials": {
            "proficiency": {},
            "activity": {
              "EN": "LB_IGH_worksheet_EN.pdf"
            },
            "lesson_plan": {}
          }
        }
      ]
    }

}

const csvToArray=(str, piece = 1)=> {
  let array = str.split(",")
  if(array.length > piece)
      return array.slice(0,piece).join(", ")
  return str
}

const findByPath = (path) => {
  let result = books().library.find(x=>x.path  === path)
  if(result)
    return result
  return {title:{EN:""},materials:{activity:{EN:""}}}
}

const getBookType = (path) => {
  const book = findByPath(path)
  if(book){
    let content_type = book.content;
    if(content_type && content_type === "word-book") return "Wordbook";
    if(content_type && content_type === "letter-book") return "Letterbook";
    if(!content_type) return "Storybook";
  }
  return "-";
}

const isLetterBook = (path) => {
  const book = findByPath(path)
  if(book){
    const content_type = book.content;
    if(content_type && content_type === "letter-book") return true;
  }
  return false;
}

const READERS_TYPE = { EMERGENT: { label: "emergent-readers" }, PRE_READERS: { label: "pre-readers" }, READING_FLUENCY: { label: "reading-fluency" } }
const getReaders = (readers_type) => {
    return unit_map.filter(x=> x.readers_type === readers_type.label)
}
const getBookReportThumbnail=(book_path)=>{
  let result = thumbs.find(x=>x.book_path==book_path)
  if(result)
    return S3_SCOPE_AND_SEQUENCE_URLS.book_report_thumbnails+result.filename
  return ""
}
const getBookReportPDF=(book_path)=>{
  let result = rpt_pdfs.find(x=>x.book_path==book_path)
  if(result)
    return S3_SCOPE_AND_SEQUENCE_URLS.book_report_pdfs+result.filename
  return ""
}

const getActivitySheetURL = (book_path) => {
  let book_meta = findByPath(book_path)
  if(book_meta!=null){
    return S3_SCOPE_AND_SEQUENCE_URLS.activity_sheet_frag+book_path+"/activity/EN/"+book_meta["materials"]["activity"]["EN"]
  }
  return ""
}

const getLessonPlans = (unit_num, category) =>{
  let result = ""
  if(category===READERS_TYPE.PRE_READERS.label && pre_readers_map){
    result = pre_readers_map.find(x=>x.unit_num  === unit_num)
    if(result)
      return S3_SCOPE_AND_SEQUENCE_URLS.lesson_plan_pre_readers+result.filename
  }
  if(category===READERS_TYPE.EMERGENT.label && emerging_map){
    result = emerging_map.find(x=>x.unit_num  === unit_num)
    if(result)
      return S3_SCOPE_AND_SEQUENCE_URLS.lesson_plan_emergent+result.filename
  }
  // fluency
  if(category===READERS_TYPE.READING_FLUENCY.label && fluency_map){
    result = fluency_map.find(x=>x.unit_num  === unit_num)
    if(result)
      return S3_SCOPE_AND_SEQUENCE_URLS.lesson_plan_fluency+result.filename
  }

  return ""
}
const filterReadersType = (filter)=>{
  const data = getReaders(filter)
  const total_units = new Set(data.map(unit=> unit.unit_num))

  const build_rows = (units, data) => {
      return Array.from(units).map(u => {
          const books = data.filter(x => x.unit_num === u);
          return { "unit": u , "row_data": books } })
  }
  return build_rows(total_units, data)
}

export { books,findByPath,getReaders,getBookReportThumbnail,getBookReportPDF,getActivitySheetURL, getLessonPlans, READERS_TYPE,getBookType,isLetterBook,filterReadersType,csvToArray };
